import './Chip.scss'
import React from 'react'
import BEMHelper from 'react-bem-helper'
import useTranslations from '@src/hooks/useTranslations'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'

const bem = new BEMHelper('chip')

const Chip = ({ label = '', labelCarType, onDelete = () => {}, fullWidth }) => {
	const t = useTranslations()
	return (
		<div {...bem('', fullWidth ? 'fullwidth' : null)}>
			<div {...bem('text')}>
				<p className='tiny'>{label}</p>
				<p className='tiny'>
					{labelCarType
						.map((cartype) => t(`kinto.carfilter.${cartype}`))
						.join(', ')}
				</p>
			</div>
			<div {...bem('close-button')}>
				<IconButton {...bem('close-button')} onClick={onDelete}>
					<CloseIcon {...bem('close-button-icon')} />
				</IconButton>
			</div>
		</div>
	)
}

export default Chip
