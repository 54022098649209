import './BookingConfirmed.scss'
import React, { useEffect } from 'react'
import BEMHelper from 'react-bem-helper'
import { useConnect } from 'redux-bundler-hook'
import { navigate } from 'gatsby'
import { BrickResolver } from '@src/utils/BrickResolver'
import usePageContextData from '@src/utils/brickresolvers/usePageContextData'
import CarCardCheckout from '@src/components/booking/CarCardCheckout'
import StationCard from '@src/components/booking/StationCard'
import { ReactComponent as Close } from '@src/assets/icons/cancel.svg'
import DatePicker from '@src/components/booking/DatePicker'
import useTranslations from '@src/hooks/useTranslations'
import { googleAnalytics } from '@src/api/googleanalytics/googleAnalytics'
import { pushBooking } from '@src/api/facebook/facebook'
import useCustomerInfo from '@src/hooks/useCustomerInfo'
import { localizedUrl } from '@src/utils/navigate'

const bem = new BEMHelper('booking')

const BookingConfirmed = ({ customPageContext }) => {
	const t = useTranslations()
	const customerInfo = useCustomerInfo()
	const {
		selectedCar,
		bookingInfo: { startDate, startTime, endDate, endTime },
		selectedStation,
		doResetBookingInfo,
		returnToBookingAfterRegistration,
		language,
		service,
	} = useConnect(
		'selectSelectedCar',
		'selectBookingInfo',
		'selectSelectedStation',
		'doResetBookingInfo',
		'selectReturnToBookingAfterRegistration',
		'selectLanguage',
		'selectService',
	)

	let bookingConfirmedPageContextData = usePageContextData({
		pageName: localizedUrl('/booking', language, service),
	})

	if (customPageContext) {
		bookingConfirmedPageContextData = customPageContext
	}

	let registrationOnBookingConfirmedPageContextData = usePageContextData({
		pageName: localizedUrl('/registrationOnBooking', language, service),
	})

	if (customPageContext) {
		registrationOnBookingConfirmedPageContextData = customPageContext
	}

	useEffect(() => {
		return () => {
			doResetBookingInfo()
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	useEffect(() => {
		googleAnalytics.pushVirtualPageView(
			'/virtual/stations/bookingconfirmed/' +
				customerInfo.customerId +
				'/' +
				selectedCar.price.amount,
			'Booking confirmed for ' +
				customerInfo.customerId +
				', hourly price ' +
				selectedCar.price.amount,
		)
		pushBooking()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	return (
		<div {...bem('dialog')}>
			<div {...bem('dialog-container')}>
				<div {...bem('top-container')}>
					<button
						{...bem('exit-button')}
						onClick={() => navigate(localizedUrl('/', language, service))}
					>
						<Close />
					</button>
					<h1 className='h2'>{t('kinto.booking.booking-confirmed-title')}</h1>
				</div>
				<StationCard station={selectedStation} />
				<CarCardCheckout car={selectedCar} />
				<div {...bem('booking-container', 'checkout')}>
					<div>
						<DatePicker
							dateActive={true}
							chosenDate={true}
							date={new Date(startDate)}
							timeActive={true}
							chosenTime={startTime}
							disabled={true}
							checkout={true}
						/>
					</div>
					<div>
						<DatePicker
							dateActive={true}
							chosenDate={true}
							date={new Date(endDate)}
							timeActive={true}
							chosenTime={endTime}
							disabled={true}
							checkout={true}
						/>
					</div>
				</div>
				<div {...bem('confirmed-mail')}>
					<p className='small'>{t('kinto.booking.confirm-sent-with-email')}</p>
				</div>
			</div>
			{returnToBookingAfterRegistration ? (
				<BrickResolver
					pageContext={registrationOnBookingConfirmedPageContextData}
				></BrickResolver>
			) : (
				<BrickResolver
					pageContext={bookingConfirmedPageContextData}
				></BrickResolver>
			)}
		</div>
	)
}

export default BookingConfirmed
