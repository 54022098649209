import './StationFilterMobile.scss'
import React, { useState, useRef, useEffect } from 'react'
import BEMHelper from 'react-bem-helper'
import usePosition from '@src/hooks/usePosition'
import useTranslations from '@src/hooks/useTranslations'
import events, { EVENT_CENTER_MAP_ON_POSITION } from '@src/utils/events'
import { ReactComponent as Car } from '@src/assets/icons/car.svg'
import ExpansionPanel from '@material-ui/core/ExpansionPanel'
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary'
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import FormGroup from '@material-ui/core/FormGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import StationsAutocompleteSearchField from '@src/components/StationsAutocompleteSearchField'
import Chip from '@src/components/Chip'
import { useConnect } from 'redux-bundler-hook'
import { SITE_TYPES } from '@src/bundles/site'

const bem = new BEMHelper('stations-filter-mobile')

const StationsMobileSearchListHeader = ({
	clearSelectedStations,
	stations,
	onSelected,
	carSizes,
	handleChange,
	chosenCars,
	resetChosenCars,
	searchValue,
	setSearchValue,
}) => {
	const t = useTranslations()
	const position = usePosition()
	const [isFilterOpen, setIsFilterOpen] = useState(false)
	const filterRef = useRef(null)

	const { service } = useConnect('selectService')

	const onValueSelected = (value) => {
		if (value.place_id) {
			setSearchValue(value.description.split(',')[0])
		} else if (value.location) {
			setSearchValue(value.location.display_name)
		}
		onSelected(value)
		setIsFilterOpen(false)
	}

	const clearSearchField = () => {
		setSearchValue(null)
		resetChosenCars()
		clearSelectedStations()
	}

	const handleTouchOutside = (event) => {
		if (filterRef.current && !filterRef.current.contains(event.target)) {
			setIsFilterOpen(false)
		}
	}

	const handleClickOutside = (event) => {
		if (filterRef.current && !filterRef.current.contains(event.target)) {
			setIsFilterOpen(false)
		}
	}

	useEffect(() => {
		document.addEventListener('mousedown', handleClickOutside)
		return () => document.removeEventListener('mousedown', handleClickOutside)
	})

	useEffect(() => {
		document.addEventListener('touchstart', handleTouchOutside)
		return () => document.removeEventListener('touchstart', handleTouchOutside)
	})

	return (
		<div {...bem('')}>
			<div
				{...(isFilterOpen
					? { ...bem('filter', 'open') }
					: { ...bem('filter') })}
				ref={filterRef}
			>
				<div
					{...(isFilterOpen
						? { ...bem('top-container', 'open') }
						: { ...bem('top-container') })}
				>
					<button onClick={() => setIsFilterOpen(true)} {...bem('open-button')}>
						<Car /> <p className='h4'>{t('kinto.stations.filter')}</p>
					</button>
					{isFilterOpen && (
						<button onClick={() => setIsFilterOpen(false)}>
							<p>{t('kinto.stations.filter-hide')}</p>
						</button>
					)}
				</div>

				{isFilterOpen && (
					<>
						<div {...bem('search-bar')}>
							{!searchValue && (
								<StationsAutocompleteSearchField
									stations={stations}
									onSelected={onValueSelected}
									goToCurrentPositionDiabeled={!position.latitude}
									onIconClick={() =>
										events.emit(EVENT_CENTER_MAP_ON_POSITION, position)
									}
								/>
							)}
						</div>
						{service !== SITE_TYPES.FLEX && (
							<div {...bem('select-car-type')}>
								<ExpansionPanel>
									<ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
										<p>{t('kinto.stations.filter-mobile')}</p>
									</ExpansionPanelSummary>
									<ExpansionPanelDetails>
										<FormGroup>
											{Object.entries(carSizes).map(([key, checked]) => (
												<FormControlLabel
													key={key}
													control={
														<Checkbox
															color='primary'
															checked={checked}
															onChange={handleChange}
															name={key}
														/>
													}
													label={t(`kinto.carfilter.${key}`)}
												/>
											))}
										</FormGroup>
									</ExpansionPanelDetails>
								</ExpansionPanel>
							</div>
						)}
					</>
				)}
			</div>
			{(searchValue || chosenCars.length > 0) && (
				<Chip
					fullWidth
					onDelete={clearSearchField}
					label={searchValue}
					labelCarType={chosenCars}
				/>
			)}
		</div>
	)
}

export default StationsMobileSearchListHeader
