import React, { useRef, useState, useMemo, useEffect } from 'react'
import BEMHelper from 'react-bem-helper'
import throttle from 'lodash/throttle'
import get from 'lodash/get'

import useAutocomplete from '@material-ui/lab/useAutocomplete'
import { makeStyles } from '@material-ui/core/styles'
import FormControl from '@material-ui/core/FormControl'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import InputAdornment from '@material-ui/core/InputAdornment'
import Paper from '@material-ui/core/Paper'
import TextField from '@material-ui/core/TextField'

import useTranslations from '@src/hooks/useTranslations'

import events, { EVENT_CENTER_MAP_ON_POSITION } from '@src/utils/events'

import NearMeIcon from '@material-ui/icons/NearMeOutlined'
import { ReactComponent as Parking } from '@src/assets/icons/parking.svg'
import { ReactComponent as Location } from '@src/assets/icons/geolocation.svg'

const bem = new BEMHelper('stations')

const autocompleteService = { current: null }

const useStyles = makeStyles((theme) => ({
	root: {
		position: 'relative',
		backgroundColor: '#fff !important',
		height: '3.75rem',
		paddingRight: '.625rem',
		borderRadius: '0.375rem',
		'&:hover': {
			backgroundColor: '#fff',
		},
	},
	input: {
		padding: '26px 12px 10px',
	},
	dropdown: {
		marginTop: 0,
		borderRadius: '0.375rem',
		margin: 0,
		padding: 0,
		zIndex: 10,
		position: 'absolute',
		top: '100%',
		maxHeight: '17rem',
		backgroundColor: theme.palette.background.paper,
		overflow: 'auto',
	},
	icon: {
		display: 'flex',
		marginRight: '1rem',
		marginLeft: '1rem',
	},
	iconButton: {
		padding: 0,
	},
	label: {
		color: '#2c353b',
		transform: 'translate(12px, 22px) scale(1)',
	},
}))

export default function StationsAutocompleteSearchField({
	stations = [],
	onSelected,
	goToCurrentPositionDiabeled,
	onIconClick = () => {},
}) {
	const t = useTranslations()
	const classes = useStyles()
	const [inputValue, setInputValue] = useState('')
	const [selectedValue, setSelectedValue] = useState(null)
	const [options, setOptions] = useState(stations)
	let inputRef = useRef(null)
	let formControlRef = useRef(null)

	const {
		getRootProps,
		getInputProps,
		getListboxProps,
		getOptionProps,
		groupedOptions,
	} = useAutocomplete({
		id: 'use-autocomplete-search',
		options: options,
		defaultValue: null,
		inpotValue: selectedValue,
		freeSolo: true,
		clearOnBlur: true,
		filterSelectedOptions: true,
		clearOnEscape: true,
		onClose: () => setInputValue(''),
		onChange: handleSelectedChanged,
		onInputChange: (ev, newInputValue) => setInputValue(newInputValue),
		getOptionSelected: (option, value) => {
			if (
				value &&
				option &&
				value.place_id &&
				option.place_id &&
				value.place_id === option.place_id
			) {
				return true
			} else if (
				value &&
				option &&
				option.location &&
				value.location &&
				option.id === value.id
			) {
				return true
			}
			return false
		},
		getOptionLabel: (option) => {
			return option.location
				? option.location.display_name
				: option.place_id
				? option.description
				: typeof option === 'string'
				? option
				: ''
		},
		filterOptions: (options) => {
			function hasValue(valueString) {
				return valueString.toLowerCase().indexOf(inputValue.toLowerCase()) > -1
			}
			return options.filter((option) => {
				if (!option) return false
				if (option.location) {
					return (
						hasValue(option.name) ||
						hasValue(option.location.display_name) ||
						hasValue(option.location.formatted_address)
					)
				}
				return true
			})
		},
	})

	function handleSelectedChanged(event, value, reason) {
		if (!value || value === '') {
			return
		}
		if (typeof value === 'string') {
			if (groupedOptions.length > 0) {
				value = groupedOptions[0]
			}
		}
		if (value.place_id) {
			const geocoder = new window.google.maps.Geocoder()
			geocoder.geocode({ placeId: value.place_id }, (results, status) => {
				if (status !== 'OK') {
					window.alert('Failed to look up informaion: ' + status)
					return
				}
				events.emit(EVENT_CENTER_MAP_ON_POSITION, {
					latitude: results[0].geometry.location.lat(),
					longitude: results[0].geometry.location.lng(),
				})
			})
		} else if (value.location) {
			events.emit(EVENT_CENTER_MAP_ON_POSITION, {
				latitude: value.location.lat,
				longitude: value.location.lng,
			})
		}
		onSelected(value)
		setSelectedValue('')
	}

	const getPlacePredictionsMemo = useMemo(
		() =>
			throttle((request, callback) => {
				autocompleteService.current.getPlacePredictions(request, callback)
			}, 200),
		[],
	)

	useEffect(() => {
		setOptions(stations)
	}, [stations])

	useEffect(() => {
		let active = true

		if (
			!autocompleteService.current &&
			get(window, 'google.maps.places.AutocompleteService')
		) {
			autocompleteService.current = new window.google.maps.places.AutocompleteService()
		}
		if (!autocompleteService.current) {
			return undefined
		}

		if (inputValue !== '') {
			getPlacePredictionsMemo(
				{ input: inputValue, componentRestrictions: { country: 'se' } },
				(results) => {
					if (active) {
						setOptions([].concat(stations, results))
					}
				},
			)
		}

		return () => {
			active = false
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [inputValue, getPlacePredictionsMemo, setOptions])

	return (
		<>
			<FormControl ref={formControlRef} {...getRootProps()} fullWidth>
				<TextField
					label={t('kinto.stations.search-parking')}
					variant='filled'
					ref={inputRef}
					fullWidth
					onKeyPress={(ev) => {
						if (ev.key === 'Enter') {
							ev.preventDefault()
						}
					}}
					InputLabelProps={{
						classes: {
							root: classes.label,
						},
					}}
					InputProps={{
						disableUnderline: true,
						classes: {
							root: classes.root,
							focused: classes.root,
							input: classes.input,
						},
						endAdornment: (
							<InputAdornment position='end'>
								<IconButton
									{...bem('near-me')}
									tabIndex='-1'
									disabled={goToCurrentPositionDiabeled}
									aria-label={t('kinto.stations.near.me-icon-label')}
									onClick={(ev) => {
										ev.stopPropagation()
										ev.preventDefault()
										onIconClick()
									}}
									className={classes.iconButton}
								>
									<NearMeIcon
										color={goToCurrentPositionDiabeled ? 'disabled' : 'primary'}
									/>
								</IconButton>
							</InputAdornment>
						),
					}}
					{...getInputProps()}
				/>
			</FormControl>
			{groupedOptions.length > 0 && (
				<Paper
					className={classes.dropdown}
					style={{
						width: formControlRef.current
							? formControlRef.current.offsetWidth
							: 250,
					}}
					{...getListboxProps()}
				>
					{groupedOptions.map((option, index) => (
						<Grid
							style={{ cursor: 'pointer', height: 50 }}
							container
							alignItems='center'
							{...getOptionProps({ option, index })}
						>
							<Grid item className={classes.icon}>
								{option.location ? <Parking /> : <Location />}
							</Grid>
							<Grid item xs>
								<span>
									{option.name
										? option.name
										: option.description.substr(
												0,
												option.description.lastIndexOf(','),
										  )}
								</span>
							</Grid>
						</Grid>
					))}
				</Paper>
			)}
		</>
	)
}
