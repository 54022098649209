import '@src/components/stations/StationsMap.scss'
import '@src/components/booking/Booking.scss'
import React, { useState, useEffect } from 'react'
import { useScript } from 'use-hooks'
import fromEntries from 'fromentries'
import usePosition from '@src/hooks/usePosition'
import { useConnect } from 'redux-bundler-hook'
import { graphql, useStaticQuery } from 'gatsby'
import { pickBy, keys, uniq, sortBy } from 'lodash'
import CircularProgress from '@material-ui/core/CircularProgress'
import BEMHelper from 'react-bem-helper'
import { tabletSize } from '@src/javascript/variables'
import { distanceInMeterBetweenEarthCoordinates } from '@src/utils/calculations'
import Map from '@src/components/Map'
import StationsMapList from '@src/components/stations/StationsMapList'
import StationsMobileList from '@src/components/stations/StationsMobileList'
import StationsMobileSearchListHeader from '@src/components/stations/StationsMobileSearchListHeader'
import StationsSearchListHeader from '@src/components/stations/StationsSearchListHeader'
import Account from '@src/components/account/Account'
import BookingConfirmed from '@src/components/booking/pages/BookingConfirmed'
import SelectCar from '@src/components/booking/pages/SelectCar'
import SelectBookingDates from '@src/components/booking/pages/SelectBookingDates'
import Checkout from '@src/components/booking/pages/Checkout'
import MobileBottomContainer from '@src/components/MobileBottomContainer'
import { azureApiGatewayGet } from '@src/api/azureApiGateway/client'
import ErrorModal from '@src/components/ErrorModal'
import events, { EVENT_SHOW_LOGIN_MODAL } from '@src/utils/events'
import useSetting from '@src/hooks/useSetting'
import { SITE_TYPES } from '@src/bundles/site'

const bem = new BEMHelper('stations')
const bemBooking = new BEMHelper('booking')

const FALLBACK_LAT =
	parseFloat(process.env.GATSBY_FALLBACK_COORDINATE_LAT) || 59.330783
const FALLBACK_LNG =
	parseFloat(process.env.GATSBY_FALLBACK_COORDINATE_LNG) || 18.057943

const getLocationFromPlace = (value) => {
	return new Promise((resolve, reject) => {
		if (typeof window === 'undefined' || !window.google) {
			return reject(new Error('Google Maps Api not initialized'))
		}
		const geocoder = new window.google.maps.Geocoder()
		geocoder.geocode({ placeId: value.place_id }, (results, status) => {
			if (status !== 'OK') {
				reject()
			} else {
				resolve(results[0])
			}
		})
	})
}

const getLocationFromURL = (value) => {
	return new Promise((resolve, reject) => {
		if (typeof window === 'undefined' || !window.google) {
			return reject(new Error('Google Maps Api not initialized'))
		}
		const geocoder = new window.google.maps.Geocoder()
		geocoder.geocode(
			{ address: value.place_area + ', Sweden' },
			(results, status) => {
				if (status !== 'OK') {
					reject()
				} else {
					resolve(results[0])
				}
			},
		)
	})
}

export const BOOKING_STEPS = {
	SELECT_CAR: 0,
	SELECT_DATES: 1,
	CHECKOUT: 2,
	CONFIRMED_BOOKING: 3,
	REGISTER_CUSTOMER: 100,
	LOGIN_CUSTOMER: 200,
	SELECT_PAYMENT_METHOD: 300,
}

const query = graphql`
	query getCars {
		pimcore {
			getVehicleTypeListing {
				edges {
					node {
						VehicleType
						Size
					}
				}
			}
		}
	}
`

const Stations = ({ location = {} }) => {
	const getSetting = useSetting()
	const googleMapUri = getSetting('googleMapUri', process.env.GOOGLE_MAPS_URL)
	const [loaded, loadError] = useScript(googleMapUri)
	const [loading, setLoading] = useState(true)
	const [isMobile, setIsMobile] = useState(true)
	const { pimcore } = useStaticQuery(query)

	const {
		doSelectStation,
		doResetSelectedStation,
		selectedStation,
		previousSelectedStation,
		isCarSelected,
		service,
	} = useConnect(
		'doSelectStation',
		'doResetSelectedStation',
		'selectSelectedStation',
		'selectPreviousSelectedStation',
		'selectIsCarSelected',
		'selectService',
	)

	const [searchValue, setSearchValue] = React.useState(null)

	useEffect(() => {
		const handleResize = () => {
			setIsMobile(
				typeof window === 'undefined' || window.innerWidth < tabletSize,
			)
		}
		window.addEventListener('resize', handleResize)
		setIsMobile(typeof window === 'undefined' || window.innerWidth < tabletSize)
		setLoading(false)
		return () => {
			window.removeEventListener('resize', handleResize)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [doResetSelectedStation])

	const [stations, setStations] = useState([])
	const [visibleStations, setVisibleStations] = useState([])
	const [filteredStations, setFilteredStations] = useState([])
	const [sizesArray, setSizesArray] = useState([])
	const [selectedLocation, setSelectedLocation] = useState(null)
	const [mapHasMoved, setMapHasMoved] = useState(false)

	const updateSelectedLocation = async (selLoc) => {
		try {
			const geoData = await getLocationFromPlace(selLoc)
			setSelectedLocation({ ...selLoc, geoData })
		} catch (e) {
			console.log('Trying to determine latlang before api init, needs a fix', e)
		}
	}

	const updateLinkedLocation = async (selLoc) => {
		try {
			const geoData = await getLocationFromURL(selLoc)
			setSelectedLocation({ ...selLoc, geoData })
		} catch (e) {
			console.log('Trying to determine latlang before api init, needs a fix', e)
		}
	}

	useEffect(() => {
		setFilteredStations(visibleStations)
	}, [visibleStations])

	useEffect(() => {
		if (location && location.state && location.state.selectedStation) {
			if (
				!selectedStation ||
				selectedStation.id === location.state.selectedStation.id
			) {
				doSelectStation(location.state.selectedStation)
				setSelectedLocation(null)
			}
		}

		if (location && location.state && location.state.selectedLocation) {
			if (
				!selectedLocation ||
				selectedLocation.place_id !== location.state.selectedLocation.place_id
			) {
				if (location.state.selectedLocation.place_id) {
					updateSelectedLocation(location.state.selectedLocation)
				} else if (location.state.selectedLocation.place_area) {
					updateLinkedLocation(location.state.selectedLocation)
				}
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [location.state, loaded])

	const position = usePosition()

	const sortVisibleStations = (stations) => {
		if (position && !position.error && position.latitude) {
			stations.sort((a, b) => {
				const distA = distanceInMeterBetweenEarthCoordinates(
					position.latitude,
					position.longitude,
					a.location.lat,
					a.location.lng,
				)
				const distB = distanceInMeterBetweenEarthCoordinates(
					position.latitude,
					position.longitude,
					b.location.lat,
					b.location.lng,
				)
				return distA < distB ? -1 : 1
			})
		}
		setVisibleStations(stations)
	}

	const detectMapMove = () => {
		setMapHasMoved(true)
	}

	async function runAzureRequest() {
		const response =
			service === SITE_TYPES.FLEX
				? await azureApiGatewayGet('station?tag=Lease')
				: await azureApiGatewayGet('station?exclude_tag=Lease')

		setStations(
			response.data && response.data.stations ? response.data.stations : [],
		)
	}

	useEffect(() => {
		if (stations.length <= 0) {
			runAzureRequest()
		}

		stations.forEach((station) => {
			const cars = pimcore.getVehicleTypeListing.edges.map(({ node }) => node)
			let array = []
			array = station.vehicles.map((vehicle) => {
				const car = cars.find(
					(thisCar) =>
						thisCar.VehicleType === vehicle.vehicle_type_id.toString(),
				)
				if (car && car.Size) {
					setSizesArray((sizesArray) => [...sizesArray, car.Size])
					return car.Size
				}
			})
			station.sizes = array
		})
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [stations])

	const onCloseBooking = () => {
		setSearchValue(null)
		doSelectStation(null)
	}

	// Filter cartype
	const [chosenCarTypes, setChosenCarTypes] = useState([])
	const [chosenCarTypesMobile, setChosenCarTypesMobile] = useState()
	const [carSizesInitial, setCarSizesInitial] = useState({})

	useEffect(() => {
		const sizesObject = {}
		let orderedArray = []
		orderedArray = uniq(sizesArray)
		orderedArray = sortBy(orderedArray, function(element) {
			var rank = {
				small: 1,
				medium: 2,
				mediumSuv: 3,
				large: 4,
				van: 5,
				luxury: 6,
				hydrogen: 7,
			}
			return rank[element]
		})

		orderedArray.forEach((item) => {
			sizesObject[item] = false
		})
		setCarSizesInitial(sizesObject)
	}, [sizesArray])

	useEffect(() => {
		const urlParams = new URLSearchParams(location.search)
		const params = fromEntries(urlParams)
		const { carType } = params
		let carSizesInitialLink = carSizesInitial
		if (carType) {
			setChosenCarTypesMobile({ ...carSizesInitialLink, [carType]: true })
		} else {
			setChosenCarTypesMobile(carSizesInitial)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [carSizesInitial])

	const handleChangeDesktop = (event) => {
		setChosenCarTypes(event.target.value)
	}

	const handleChangeMobile = (event) => {
		setChosenCarTypesMobile({
			...chosenCarTypesMobile,
			[event.target.name]: event.target.checked,
		})
	}

	const resetChosenCars = () => {
		setChosenCarTypesMobile(carSizesInitial)
		setChosenCarTypes([])
	}

	useEffect(() => {
		const filterTrue = pickBy(chosenCarTypesMobile)
		const filterKeys = keys(filterTrue)
		setChosenCarTypes(filterKeys)
	}, [chosenCarTypesMobile])

	useEffect(() => {
		if (chosenCarTypes.length > 0) {
			const filteredCars = visibleStations.filter(({ sizes }) => {
				return sizes.some((size) => chosenCarTypes.includes(size))
			})
			setFilteredStations(filteredCars)
		} else {
			setVisibleStations(visibleStations)
		}
	}, [chosenCarTypes, visibleStations])

	useEffect(() => {
		if (isCarSelected) {
			setNextStep(BOOKING_STEPS.SELECT_DATES)
		} else if (selectedStation !== null) {
			setNextStep(BOOKING_STEPS.SELECT_CAR)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	const [currentStep, setNextStep] = useState(0)
	const [errorMessage, setErrorMessage] = useState(null)

	const showMap = currentStep < BOOKING_STEPS.CHECKOUT

	const renderCurrentBookingStep = () => {
		let Page = SelectCar
		switch (currentStep) {
			case BOOKING_STEPS.SELECT_DATES:
				Page = SelectBookingDates
				break
			case BOOKING_STEPS.CHECKOUT:
				Page = Checkout
				break
			case BOOKING_STEPS.CONFIRMED_BOOKING:
				Page = BookingConfirmed
				break
			case BOOKING_STEPS.REGISTER_CUSTOMER:
				Page = Account
				break
			case BOOKING_STEPS.LOGIN_CUSTOMER:
				events.emit(EVENT_SHOW_LOGIN_MODAL, {
					hideRegistration: true,
					callback: () => {
						setNextStep(BOOKING_STEPS.CHECKOUT)
					},
				})
				return
			default:
				Page = SelectCar
				break
		}
		return (
			<Page
				setNextStep={setNextStep}
				setLoading={setLoading}
				setErrorMessage={setErrorMessage}
				onClose={onCloseBooking}
				isMobile={isMobile}
			/>
		)
	}

	const Div = ({ children }) => <div {...bem('desktop')}>{children}</div>

	const ContainerComponent =
		isMobile && currentStep === 0 ? MobileBottomContainer : Div

	function calculateSizeValues() {
		const values = {
			listWidth: 500,
			mapWidth: 500,
			mapHeight: 500,
		}

		if (typeof window === 'undefined') {
			return values
		}

		if (window.innerWidth <= tabletSize) {
			values.mapWidth = window.innerWidth
			values.listWidth = window.innerWidth
			values.mapHeight = window.innerHeight - 62
		} else {
			values.mapWidth = window.innerWidth - 800
			values.listWidth = 800
			values.mapHeight = window.innerHeight - 80
		}

		return values
	}

	const onSearchValueSelected = (value) => {
		if (value.location) {
			doSelectStation(value)
			setSelectedLocation(null)
		} else if (value.place_id) {
			updateSelectedLocation(value)
			doSelectStation(null)
		}
	}

	// TODO: recalc only on resize
	const sizes = calculateSizeValues()

	return (
		<div id='stations' {...bem('')}>
			{loading ? (
				<CircularProgress />
			) : (
				<>
					{showMap && (
						<div id='map-container' {...bem('map-wrapper')}>
							<Map
								loaded={loaded}
								loadError={loadError}
								selectedStation={selectedStation}
								previousSelectedStation={previousSelectedStation}
								showNearByStations={
									location.state &&
									location.state['showNearByStations'] !== undefined
										? location.state.showNearByStations
										: true
								}
								zoomControl={!isMobile}
								mapTypeControl={!isMobile}
								fullscreenControl={isMobile}
								height={sizes.mapHeight}
								location={location}
								isMobile={isMobile}
								selectedLocation={selectedLocation}
								stations={stations}
								filteredStations={filteredStations}
								chosenFilters={chosenCarTypes}
								setVisibleStations={sortVisibleStations}
								mapMoved={detectMapMove}
								onMarkerClicked={(station) => {
									if (station) {
										doSelectStation(station)
										setNextStep(BOOKING_STEPS.SELECT_CAR)
										setSelectedLocation(null)
									}
								}}
								lat={
									location.state && location && location.state.lat
										? location.state.lat
										: FALLBACK_LAT
								}
								lng={
									location.state && location && location.state.lng
										? location.state.lng
										: FALLBACK_LNG
								}
							/>

							{isMobile && (
								<StationsMobileSearchListHeader
									stations={stations}
									selectedStation={selectedStation}
									clearSelectedStations={doResetSelectedStation}
									onSelected={onSearchValueSelected}
									carSizes={chosenCarTypesMobile}
									handleChange={handleChangeMobile}
									chosenCars={chosenCarTypes}
									resetChosenCars={resetChosenCars}
									searchValue={searchValue}
									setSearchValue={setSearchValue}
								/>
							)}
						</div>
					)}
					{selectedStation ? (
						<ContainerComponent>
							<div {...bemBooking('')}>
								{currentStep === 0 ? (
									<div {...bemBooking('container')}>
										{renderCurrentBookingStep()}
									</div>
								) : (
									<div {...bemBooking('container-full')}>
										{renderCurrentBookingStep()}
									</div>
								)}
								{loading && (
									<div {...bemBooking('loading-container')}>
										<CircularProgress size='15rem' />
									</div>
								)}
								{errorMessage && (
									<ErrorModal onClose={() => setErrorMessage(null)}>
										{errorMessage}
									</ErrorModal>
								)}
							</div>
						</ContainerComponent>
					) : isMobile ? (
						<StationsMobileList
							stations={
								chosenCarTypes.length > 0 ? filteredStations : visibleStations
							}
							selectedLocation={selectedLocation}
							onSelected={doSelectStation}
							placeInfo={selectedLocation}
							mapMoved={mapHasMoved}
						/>
					) : (
						<div {...bem('desktop')}>
							<StationsSearchListHeader
								stations={stations}
								carSizes={carSizesInitial}
								handleChange={handleChangeDesktop}
								onSelected={onSearchValueSelected}
								chosenCarTypes={chosenCarTypes}
							/>
							<StationsMapList
								stations={
									chosenCarTypes.length > 0 ? filteredStations : visibleStations
								}
								selectedLocation={selectedLocation}
								onSelected={onSearchValueSelected}
								placeInfo={selectedLocation}
								mapMoved={mapHasMoved}
							/>
						</div>
					)}
				</>
			)}
		</div>
	)
}

export default Stations
