import './InformationBox.scss'
import React from 'react'
import BEMHelper from 'react-bem-helper'

const bem = new BEMHelper('information-box')

const InformationBox = ({ color, text, icon }) => {
	return (
		<div
			{...bem(
				'',
				color === 'green'
					? 'green'
					: color === 'blue'
					? 'blue'
					: color === 'red'
					? 'red'
					: null,
			)}
		>
			<div
				{...bem(
					'color',
					color === 'green'
						? 'green'
						: color === 'blue'
						? 'blue'
						: color === 'red'
						? 'red'
						: null,
				)}
			></div>
			{icon && icon}
			<div {...bem('text')}>
				<p className='h6'>{text}</p>
			</div>
		</div>
	)
}

export default InformationBox
