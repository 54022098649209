import './SelectCar.scss'
import React, { useEffect, useState } from 'react'
import { navigate } from 'gatsby'
import { graphql, useStaticQuery } from 'gatsby'
import { useConnect } from 'redux-bundler-hook'
import useSetting from '@src/hooks/useSetting'
import useTranslations from '@src/hooks/useTranslations'
import BEMHelper from 'react-bem-helper'
import IconButton from '@material-ui/core/IconButton'
import Button from '@material-ui/core/Button'
import { ReactComponent as Close } from '@src/assets/icons/cancel.svg'
import SelectCarCard from '@src/components/booking/SelectCarCard'
import { googleAnalytics } from '@src/api/googleanalytics/googleAnalytics'
import { get, uniqBy } from 'lodash'
import Modal from '@src/components/Modal'
import NavigateNextIcon from '@material-ui/icons/ArrowForwardIos'
import { BOOKING_STEPS } from '@src/components/stations/StationsMap'
import { SITE_TYPES } from '@src/bundles/site'
import events, { EVENT_SHOW_LOGIN_MODAL } from '@src/utils/events'
import { localizedUrl } from '@src/utils/navigate'

const bem = new BEMHelper('booking')

const query = graphql`
	query GetAvailableVehicles {
		pimcore {
			getStationListing {
				edges {
					node {
						RidecellID
						id
						ImageGallery {
							image {
								filename
								fullpath(thumbnail: "station_wayfinding_header_image")
								srcset(thumbnail: "station_wayfinding_header_image") {
									descriptor
									url
									resolutions {
										url
										resolution
									}
								}
							}
						}
						OpeningHours {
							col0
							col1
						}
					}
				}
			}
		}
	}
`

const SelectCar = ({ setNextStep, onClose }) => {
	const t = useTranslations()
	const {
		doSetBookingSelectedCar,
		selectedStation,
		isCarSelected,
		isCustomerLoggedIn,
		siteType,
		doSetSiteType,
		isMissingInformation,
		isMissingInformationBusiness,
		doSetReturnToBookingAfterRegistration,
		language,
		service,
	} = useConnect(
		'doSetBookingSelectedCar',
		'selectSelectedStation',
		'selectIsCarSelected',
		'selectIsCustomerLoggedIn',
		'selectSiteType',
		'doSetSiteType',
		'selectIsMissingInformation',
		'selectIsMissingInformationBusiness',
		'doSetReturnToBookingAfterRegistration',
		'selectLanguage',
		'selectService',
	)
	const getSetting = useSetting()
	const salesforceBaseUrl = getSetting('salesforce_stations')

	const [isModalOpen, setIsModalOpen] = useState(false)

	const { pimcore } = useStaticQuery(query)
	const stationObject = get(pimcore, 'getStationListing.edges').find(
		({ node }) => node.RidecellID === selectedStation.id,
	)
	const openingHours =
		stationObject &&
		stationObject.node.OpeningHours &&
		stationObject.node.OpeningHours.length > 0 &&
		stationObject.node.OpeningHours

	const imagePath = stationObject
		? stationObject.node.ImageGallery &&
		  stationObject.node.ImageGallery.length > 0 &&
		  `${process.env.GATSBY_PIMCORE_BASE_URL}${stationObject.node.ImageGallery[0].image.fullpath}`
		: ''

	const cars = uniqBy(selectedStation.vehicles, 'vehicle_type_id')

	const onCarSelected = (car) => {
		doSetBookingSelectedCar(car)
		setNextStep(BOOKING_STEPS.SELECT_DATES)
	}

	useEffect(() => {
		if (isCarSelected === true) {
			setNextStep(BOOKING_STEPS.SELECT_DATES)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isCarSelected])

	useEffect(() => {
		if (!Array.isArray(cars)) {
			return
		}

		const carImpressions = cars.map((car) => {
			const name = `${car.Brand} ${car.Model}`

			const carImpression = {
				name,
				id: car.id,
				price: car.RatePerHour,
			}
			return carImpression
		})

		googleAnalytics.pushProductImpressions(carImpressions)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	const triggerLogin = () => {
		events.emit(EVENT_SHOW_LOGIN_MODAL, {})
	}

	const toggleSiteType = () => {
		if (siteType === SITE_TYPES.BUSINESS) {
			doSetSiteType(SITE_TYPES.PRIVATE)
		} else {
			doSetSiteType(SITE_TYPES.BUSINESS)
		}
	}

	return (
		<>
			<div {...bem('header')}>
				{imagePath && (
					<div
						{...bem('banner')}
						style={{
							backgroundImage: `url("${imagePath}")`,
						}}
					></div>
				)}
				<div {...bem('banner-exit')}>
					<IconButton color='primary' onClick={onClose}>
						<Close />
					</IconButton>
				</div>
			</div>
			<div {...bem('station')}>
				<div>
					<h1 className='h3'>{selectedStation.name}</h1>
					<p {...bem('text')}>{selectedStation.location.display_name}</p>
					{openingHours && (
						<div {...bem('opening-hours')}>
							<h5>{t('kinto.station-opening-hours.title')}</h5>
							<p {...bem('opening-hours', 'description')}>
								{t('kinto.station-opening-hours.description')}
							</p>
							{openingHours.map((value) => {
								return (
									<div {...bem('opening-hours', 'row')} key={value[0]}>
										<div {...bem('opening-hours', 'column')}>{value.col0}</div>
										<div {...bem('opening-hours', 'column')}>{value.col1}</div>
									</div>
								)
							})}
						</div>
					)}
				</div>
				<div {...bem('banner-exit-mobile')}>
					<IconButton color='primary' onClick={onClose}>
						<Close />
					</IconButton>
				</div>
			</div>
			<div {...bem('car-list')}>
				{cars.map((car) => (
					<SelectCarCard
						key={car.vehicle_id}
						car={car}
						onSelect={onCarSelected}
					/>
				))}

				<div {...bem('wayfinding')}>
					<div>
						{siteType === SITE_TYPES.BUSINESS && !isCustomerLoggedIn ? (
							<button onClick={triggerLogin}>
								<p className='small'>{t('kinto.booking.business.login')}</p>
							</button>
						) : isCustomerLoggedIn &&
						  !isMissingInformationBusiness &&
						  isMissingInformation ? (
							<button
								onClick={() => {
									navigate(localizedUrl('/kom-igang', language, service))
									doSetReturnToBookingAfterRegistration(true)
								}}
							>
								<p className='small'>{t('kinto.booking.add-card')}</p>
							</button>
						) : siteType === SITE_TYPES.BUSINESS &&
						  isCustomerLoggedIn &&
						  !isMissingInformation ? (
							<button onClick={toggleSiteType}>
								<p className='small'>
									{t('kinto.booking.business.change-from')}
								</p>
							</button>
						) : (
							siteType === SITE_TYPES.PRIVATE &&
							isCustomerLoggedIn &&
							!isMissingInformation && (
								<button onClick={toggleSiteType}>
									<p className='small'>
										{t('kinto.booking.business.change-to')}
									</p>
								</button>
							)
						)}
					</div>
					<Button
						aria-label={t(`kinto.station-wayfinfing-button`)}
						variant='outlined'
						color='primary'
						onClick={() => setIsModalOpen(true)}
						endIcon={<NavigateNextIcon />}
						{...bem('wayfinding-button')}
					>
						{t(`kinto.station-wayfinfing-button`, [selectedStation.name])}
					</Button>
				</div>
				<Modal
					open={isModalOpen}
					handleClose={() => setIsModalOpen(false)}
					xButtonAbsolute
					bemClass={'wayfinding'}
				>
					<iframe
						src={salesforceBaseUrl + selectedStation.id}
						title='wayfinding'
					/>
				</Modal>
			</div>
		</>
	)
}

export default SelectCar
