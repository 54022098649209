import './SelectCarCard.scss'
import React, { useState } from 'react'
import BEMHelper from 'react-bem-helper'
import { get } from 'lodash'
import Button from '@material-ui/core/Button'
import Paper from '@material-ui/core/Paper'
import { useConnect } from 'redux-bundler-hook'
import useTranslations from '@src/hooks/useTranslations'
import CarAttribute from '@src/components/booking/CarAttribute'
import useSetting from '@src/hooks/useSetting'
import Modal from '@src/components/Modal'
import { EmbeddableContentPage } from '@src/templates/contentPage'
import usePageContextData from '@src/utils/brickresolvers/usePageContextData'
import { localizedUrl } from '@src/utils/navigate'

const bem = new BEMHelper('booking-cards')

const SelectCarCard = ({ car: carProp, onSelect }) => {
	const t = useTranslations()

	const { allVehicleTypes, language, service } = useConnect(
		'selectAllVehicleTypes',
		'selectLanguage',
		'selectService',
	)
	const car = allVehicleTypes.find(
		(vehicleType) =>
			vehicleType.VehicleType === carProp.vehicle_type_id.toString(),
	)

	const fullpath = get(car, 'MainImage.fullpath')
	const getSetting = useSetting()
	const cdnHost = getSetting('CDNHost', '')
	const [isModalOpen, setIsModalOpen] = useState(false)

	let carPath = ''
	if (car.ModalDocumentLink != null && car.ModalDocumentLink.path) {
		carPath = car.ModalDocumentLink.path
	}
	const carPageContextData = usePageContextData({
		pageName: localizedUrl(carPath, language, service),
	})

	return (
		<Paper {...bem('')}>
			<div {...bem('container')}>
				<div {...bem('container-image-title')}>
					<div // eslint-disable-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
						{...(carPath ? bem('image', 'modal') : bem('image'))}
						onClick={() => setIsModalOpen(true)}
						onKeyPress={() => setIsModalOpen(true)}
						role='button'
						tabIndex={0}
					>
						<img src={`${cdnHost}${fullpath}`} alt={car.Brand} />
					</div>
					<div // eslint-disable-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
						{...(carPath
							? bem('info-container', 'modal')
							: bem('info-container'))}
						onKeyPress={() => setIsModalOpen(true)}
						role='button'
						tabIndex={0}
						onClick={() => setIsModalOpen(true)}
					>
						<h2 className='h4'>{car.Brand}</h2>
						<p>{car.AddtionalInformation}</p>
						<div {...bem('attribute-container')}>
							<CarAttribute type={car.Size} />
							{car.TowBar && <CarAttribute type={'towbar'} />}
							<CarAttribute type={'seats'} numberOfSeats={car.NoOfSeats} />
						</div>
					</div>
					{carPath && (
						<Modal
							open={isModalOpen}
							handleClose={() => setIsModalOpen(false)}
							xButtonAbsolute
							noMargin
						>
							<EmbeddableContentPage
								pageContext={carPageContextData}
							></EmbeddableContentPage>
						</Modal>
					)}
				</div>

				<div {...bem('end-container')}>
					<Button
						variant='contained'
						color='primary'
						onClick={() => onSelect(car)}
					>
						{t('kinto.booking.select')}
					</Button>
				</div>
			</div>
		</Paper>
	)
}

export default SelectCarCard
