import './Checkout.scss'
import React, { useState, useEffect } from 'react'
import BEMHelper from 'react-bem-helper'
import { useConnect } from 'redux-bundler-hook'
import Button from '@material-ui/core/Button'
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore'
import CarCardCheckout from '@src/components/booking/CarCardCheckout'
import PaymentCard from '@src/components/booking/PaymentCard'
import StationCard from '@src/components/booking/StationCard'
import EliminatingExcessCard from '@src/components/booking/EliminatingExcessCard'
import DatePicker from '@src/components/booking/DatePicker'
import ErrorModal from '@src/components/ErrorModal'
import useTranslations from '@src/hooks/useTranslations'
import useCustomerInfo from '@src/hooks/useCustomerInfo'
import { googleAnalytics } from '@src/api/googleanalytics/googleAnalytics'
import { get } from 'lodash'
import { BOOKING_STEPS } from '@src/components/stations/StationsMap'
import InformationBox from '@src/components/InformationBox'
import { ReactComponent as Calendar } from '@src/assets/icons/calendar-x.svg'
import { SITE_TYPES } from '@src/bundles/site'
import TextField from '@material-ui/core/TextField'
import { smallDesktopSize } from '@src/javascript/variables'
import CheckoutRightColumn from '@src/components/booking/CheckoutRightColumn'

const bem = new BEMHelper('booking')

const Checkout = ({ setNextStep, isMobile }) => {
	const t = useTranslations()
	const customerInfo = useCustomerInfo()
	const {
		selectedCar,
		bookingInfo: {
			startDate,
			startTime,
			endDate,
			endTime,
			insurance,
			insuranceChecked,
		},
		doScheduleRental,
		selectedStation,
		siteType,
		doSetComment,
		bookingSiteType,
		businessCustomer,
		service,
		membership,
	} = useConnect(
		'selectSelectedCar',
		'selectBookingInfo',
		'doScheduleRental',
		'selectSelectedStation',
		'selectSiteType',
		'doSetComment',
		'selectBookingSiteType',
		'selectBusinessCustomer',
		'selectService',
		'selectMembership',
	)

	const [errorMessage, showErrorMessage] = useState(null)
	const [isCompleteToCheckout, setIsCompleteToCheckout] = useState(false)
	const [insuranceSelected, setInsuranceSelected] = useState(false)
	const [comment, setComment] = useState('')
	const price = get(selectedCar, 'price.amount')

	const [isDesktop, setIsDesktop] = useState(true)

	useEffect(() => {
		const handleResize = () => {
			setIsDesktop(
				typeof window === 'undefined' || window.innerWidth > smallDesktopSize,
			)
		}
		window.addEventListener('resize', handleResize)
		setIsDesktop(
			typeof window === 'undefined' || window.innerWidth > smallDesktopSize,
		)
		return () => {
			window.removeEventListener('resize', handleResize)
		}
	}, [])

	useEffect(() => {
		setInsuranceSelected(
			insurance !== undefined &&
				insurance &&
				((insurance.is_terms_condition_mandatory &&
					insuranceChecked === true) ||
					insurance === 'noInsurance' ||
					!insurance.is_terms_condition_mandatory),
		)

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [insurance, insuranceChecked])

	useEffect(() => {
		setIsCompleteToCheckout(
			siteType === SITE_TYPES.PRIVATE ||
				(siteType === SITE_TYPES.BUSINESS && !businessCustomer)
				? customerInfo.creditCards.length > 0 &&
						customerInfo.defaultCreditCard !== null &&
						insuranceSelected
				: siteType === SITE_TYPES.BUSINESS &&
						insuranceSelected &&
						comment !== '',
		)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [customerInfo, comment, insuranceSelected])

	useEffect(() => {
		setIsCompleteToCheckout(
			siteType === SITE_TYPES.FLEX &&
				customerInfo.creditCards.length > 0 &&
				customerInfo.defaultCreditCard !== null,
		)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	const onClickConfirm = async () => {
		try {
			await doScheduleRental()
			setNextStep(BOOKING_STEPS.CONFIRMED_BOOKING)
		} catch (error) {
			if (get(error, 'response.data.messages')) {
				showErrorMessage(error.response.data.messages)
			}
		}
	}

	const onClickScrollInsurance = () => {
		var element = document.getElementById('insurance')
		element.scrollIntoView({ behavior: 'smooth' })
	}

	const onClickScrollComment = () => {
		var element = document.getElementById('comment')
		element.scrollIntoView({ behavior: 'smooth' })
	}

	useEffect(() => {
		googleAnalytics.pushVirtualPageView(
			'/virtual/stations/checkout/' + customerInfo.customerId + '/' + price,
			'Checking out customer ' +
				customerInfo.customerId +
				', hourly rate ' +
				price,
		)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	const handleCommentChange = (event) => {
		setComment(event.target.value)
	}

	return (
		<div {...bem('dialog')}>
			<div {...bem('dialog-container')}>
				{errorMessage && (
					<ErrorModal onClose={() => showErrorMessage(null)}>
						{errorMessage}
					</ErrorModal>
				)}
				<div {...bem('top-container')}>
					<button
						{...bem('back-button')}
						onClick={() => setNextStep(BOOKING_STEPS.SELECT_DATES)}
					>
						<NavigateBeforeIcon />
						<p>
							{service === SITE_TYPES.FLEX
								? t('kinto.booking.flex.back')
								: t('kinto.booking.back')}
						</p>
					</button>
					<div {...bem('title')}>
						<h1 className='h3'>
							{bookingSiteType === SITE_TYPES.BUSINESS
								? t('kinto.booking.checkout-title.business')
								: t('kinto.booking.checkout-title')}
						</h1>
						{service === SITE_TYPES.SHARE && (
							<div>
								{membership !== null && membership !== 0 ? (
									<p className='smallBold'>
										{t(`kinto.membership${membership}`)}
									</p>
								) : (
									<p className='smallBold'>{t(`kinto.membership${0}`)}</p>
								)}
							</div>
						)}
					</div>
				</div>
				<StationCard station={selectedStation} isDesktop={isDesktop} />
				<CarCardCheckout car={selectedCar} />
				<div {...bem('booking-container', 'checkout')}>
					<div>
						<DatePicker
							dateActive={true}
							chosenDate={true}
							date={new Date(startDate)}
							timeActive={true}
							chosenTime={startTime}
							disabled={true}
							checkout={true}
						/>
					</div>
					<div>
						<DatePicker
							dateActive={true}
							chosenDate={true}
							date={new Date(endDate)}
							timeActive={true}
							chosenTime={endTime}
							disabled={true}
							checkout={true}
						/>
					</div>
				</div>
				{service === SITE_TYPES.FLEX && (
					<p {...bem('checkout-comment')}>{t('kinto.booking.checkout.flex')}</p>
				)}
				{bookingSiteType === SITE_TYPES.BUSINESS && (
					<div {...bem('checkout-comment')} id={'comment'}>
						<TextField
							label={t('kinto.booking.checkout.comment')}
							id={t('kinto.booking.checkout.comment')}
							variant='outlined'
							name='comment'
							required
							multiline
							value={comment}
							onChange={handleCommentChange}
							onBlur={() => doSetComment(comment)}
						/>
					</div>
				)}
				<EliminatingExcessCard />
				<PaymentCard
					selectedCar={selectedCar}
					prices={insurance}
					mobile={isMobile}
				/>
				<div {...bem('bottom-container')}>
					<div {...bem('cancellation-info')}>
						<p className='small'>
							{service === SITE_TYPES.FLEX
								? t('kinto.booking.cancellation-info.flex')
								: t('kinto.booking.cancellation-info')}
						</p>
					</div>
				</div>
				<div {...bem('button-container')}>
					<Button
						{...bem('button')}
						aria-label={t('kinto.next')}
						variant='contained'
						color='primary'
						fullWidth={isMobile}
						disabled={!isCompleteToCheckout}
						onClick={onClickConfirm}
					>
						{t('kinto.booking.confirm')}
					</Button>
					<div
						{...(isCompleteToCheckout
							? { ...bem('button-container', 'error-button', 'hidden') }
							: { ...bem('button-container', 'error-button') })}
						onClick={() =>
							bookingSiteType === SITE_TYPES.BUSINESS && comment === ''
								? onClickScrollComment()
								: !insuranceSelected && onClickScrollInsurance()
						}
						onKeyPress={() =>
							bookingSiteType === SITE_TYPES.BUSINESS && comment === ''
								? onClickScrollComment()
								: !insuranceSelected && onClickScrollInsurance()
						}
						role='button'
						tabIndex={0}
					></div>
				</div>
				{!isCompleteToCheckout && !insuranceSelected && (
					<div
						{...bem('information')}
						onClick={() => onClickScrollInsurance()}
						onKeyPress={() => onClickScrollInsurance()}
						role='button'
						tabIndex={0}
					>
						<InformationBox
							color='blue'
							text={t('kinto.checkout.insurance.none.information-bottom', [
								<u>{t('kinto.checkout.insurance.agree.link')}</u>,
							])}
							icon={<Calendar />}
						/>
					</div>
				)}
				{bookingSiteType === SITE_TYPES.BUSINESS && comment === '' && (
					<div
						{...bem('information')}
						onClick={() => onClickScrollComment()}
						onKeyPress={() => onClickScrollComment()}
						role='button'
						tabIndex={0}
					>
						<InformationBox
							color='blue'
							text={t('kinto.checkout.comment.none.information-bottom', [
								<u>{t('kinto.checkout.comment.link')}</u>,
							])}
							icon={<Calendar />}
						/>
					</div>
				)}
				{!isDesktop && <CheckoutRightColumn />}
			</div>
		</div>
	)
}

export default Checkout
