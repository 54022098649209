import React, { useEffect } from 'react'
import StationsMap from '@src/components/stations/StationsMap'
import Seo from '@src/components/Seo'
import usePageContextData from '@src/utils/brickresolvers/usePageContextData'
import { useConnect } from 'redux-bundler-hook'

const Stations = ({ location = {}, ...rest }, customPageContext = null) => {
	let bokaBilContextData = usePageContextData({
		pageName: location.pathname.replace('boka-bil', 'boka-bil-custom'),
	})

	const { doSetMembership } = useConnect('doSetMembership')

	// UsePageContext hook causes some problems so we just provide a custom pageContext
	// when rendering the page from Pimcore preview.
	if (customPageContext && customPageContext.length > 0) {
		bokaBilContextData = customPageContext
	}

	useEffect(() => {
		const urlParams = new URLSearchParams(location.search)
		const place = urlParams.get('place')

		if (urlParams.has('place')) {
			location.state = { selectedLocation: { place_area: place } }
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [location.search])

	useEffect(() => {
		doSetMembership()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	return (
		<>
			<Seo pageContextData={bokaBilContextData} />
			<StationsMap location={location} {...rest} />
		</>
	)
}

export default Stations
