import React from 'react'
import BEMHelper from 'react-bem-helper'
import useTranslations from '@src/hooks/useTranslations'
import StationsAutocompleteSearchField from '@src/components/StationsAutocompleteSearchField'
import usePosition from '@src/hooks/usePosition'
import events, { EVENT_CENTER_MAP_ON_POSITION } from '@src/utils/events'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import Checkbox from '@material-ui/core/Checkbox'
import ListItemText from '@material-ui/core/ListItemText'
import Input from '@material-ui/core/Input'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { useConnect } from 'redux-bundler-hook'
import { SITE_TYPES } from '@src/bundles/site'

const bem = new BEMHelper('stations')

const StationsSearchListHeader = ({
	stations,
	onSelected,
	carSizes,
	handleChange,
	chosenCarTypes,
}) => {
	const t = useTranslations()
	const position = usePosition()

	const { service } = useConnect('selectService')

	return (
		<div {...bem('header')}>
			<div {...bem('search-field-root')}>
				<StationsAutocompleteSearchField
					stations={stations}
					onSelected={onSelected}
					goToCurrentPositionDiabeled={!position.latitude}
					onIconClick={() =>
						events.emit(EVENT_CENTER_MAP_ON_POSITION, position)
					}
				/>
			</div>
			{service !== SITE_TYPES.FLEX && (
				<div {...bem('select-car')}>
					<FormControl variant='filled'>
						<InputLabel id='car-picker'>
							{t('kinto.stations.filter-desktop')}
						</InputLabel>
						<Select
							labelId='car-picker'
							value={chosenCarTypes}
							onChange={handleChange}
							label={t('kinto.stations.filter-desktop')}
							IconComponent={ExpandMoreIcon}
							disableUnderline
							multiple
							input={<Input />}
							MenuProps={{ variant: 'menu' }}
							renderValue={(selected) =>
								selected
									.map((carsize) => t(`kinto.carfilter.${carsize}`))
									.join(', ')
							}
						>
							{Object.entries(carSizes).map((item) => (
								<MenuItem key={item[0]} value={item[0]}>
									<Checkbox
										checked={chosenCarTypes.indexOf(item[0]) > -1}
										color='primary'
									/>
									<ListItemText primary={t(`kinto.carfilter.${item[0]}`)} />
								</MenuItem>
							))}
						</Select>
					</FormControl>
				</div>
			)}
		</div>
	)
}

export default StationsSearchListHeader
