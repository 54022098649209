import './PaymentCard.scss'
import React, { useEffect } from 'react'
import BEMHelper from 'react-bem-helper'
import { useConnect } from 'redux-bundler-hook'
import format from 'date-fns/format'
import parseISO from 'date-fns/parseISO'
import svLocale from 'date-fns/locale/sv'
import CircularProgress from '@material-ui/core/CircularProgress'
import Radio from '@material-ui/core/Radio'
import { get } from 'lodash'
import useTranslations from '@src/hooks/useTranslations'
import useCustomerInfo from '@src/hooks/useCustomerInfo'
import { SITE_TYPES } from '@src/bundles/site'

const bem = new BEMHelper('booking-cards')

const NO_CREDITCARD_SET = -1

const PaymentCard = ({ selectedCar, prices, mobile }) => {
	const t = useTranslations()
	const customerInfo = useCustomerInfo()
	const {
		doSetCustomerCreditCards,
		bookingInfo: { startTime, endTime },
		allVehicleTypes,
		bookingSiteType,
		service,
	} = useConnect(
		'doSetCustomerCreditCards',
		'selectBookingInfo',
		'selectAllVehicleTypes',
		'selectBookingSiteType',
		'selectService',
	)
	const price = get(selectedCar, 'price.amount')
	const car = allVehicleTypes.find(
		(vehicleType) => vehicleType.VehicleType === selectedCar.id.toString(),
	)

	useEffect(() => {
		if (
			customerInfo.creditCards.length <= 0 &&
			customerInfo.defaultCreditCard === null
		) {
			doSetCustomerCreditCards()
		}
	}, [customerInfo, doSetCustomerCreditCards])

	const convertDateTime = (dateTime) => {
		dateTime = format(parseISO(dateTime), 'd MMM kk.mm', {
			locale: svLocale,
		})
		return dateTime
	}

	return (
		<div {...bem('', 'no-flex no-padding payment')}>
			<h3>{t('kinto.booking.payment')}</h3>
			<div {...bem('row', 'payment')}>
				<div {...bem('payment-summary')}>
					<div>
						<p>
							{t('kinto.checkout.price.booking')} {mobile && <br />}(
							{convertDateTime(startTime)} - {convertDateTime(endTime)})
						</p>
						<p {...bem('summary-price')}>
							{prices && prices.price
								? prices.price.rental_estimate_without_addons
								: price}{' '}
							{t('kinto.checkout.price.currency')}
						</p>
					</div>
					<div>
						{prices && prices.price ? (
							<>
								<p>{t('kinto.checkout.price.insurance')}</p>
								<p {...bem('summary-price')}>
									{prices.price.addon_price_display}
								</p>
							</>
						) : (
							service !== SITE_TYPES.FLEX && (
								<p className='h6'>{t('kinto.checkout.price.insurance.none')}</p>
							)
						)}
					</div>
				</div>
				<div {...bem('payment-summary')}>
					<div>
						<p className='h4'>
							{bookingSiteType === SITE_TYPES.BUSINESS
								? t('kinto.checkout.price.sum.business')
								: t('kinto.checkout.price.sum')}
						</p>
						<p className='h4'>
							{prices && prices.price ? prices.price.amount : price}{' '}
							{t('kinto.checkout.price.currency')}
						</p>
					</div>
					{service !== SITE_TYPES.FLEX && (
						<div>
							<p className='h5'>
								{bookingSiteType === SITE_TYPES.BUSINESS
									? t('kinto.checkout.price.kilometer.business')
									: t('kinto.checkout.price.kilometer')}
							</p>
							<p className='h5'>
								+ {bookingSiteType === SITE_TYPES.PRIVATE
									? car.RatePerKilometer
									: car.RatePerKilometerBusiness}{' '}
								{t('kinto.checkout.price.priceKilometer')}
							</p>
						</div>
					)}
					<p>
						{service === SITE_TYPES.FLEX
							? t('kinto.checkout.flex.price.includes')
							: t('kinto.checkout.price.includes')}
					</p>
				</div>
			</div>

			<div {...bem('row')}>
				<div {...bem('payment-wrapper')}>
					{bookingSiteType === SITE_TYPES.BUSINESS ? (
						<p {...bem('payment-text-business')}>
							{t('kinto.checkout.payment.business') +
								' ' +
								customerInfo.businessEntity.legal_name}
						</p>
					) : customerInfo.defaultCreditCard ? (
						<>
							{customerInfo.defaultCreditCard === NO_CREDITCARD_SET && (
								<p className='tiny'>{t('kinto.error.no-card-available')}</p>
							)}
							{customerInfo.defaultCreditCard.last_four_digits && (
								<>
									<Radio checked={true} color='primary' />
									<div {...bem('payment-details')}>
										<p className='small'>{`*** *** *** ${customerInfo.defaultCreditCard.last_four_digits}`}</p>
										<p className='small'>
											{t('kinto.booking.card-expiry-date', [
												`${
													customerInfo.defaultCreditCard.expiry_month
												}/${customerInfo.defaultCreditCard.expiry_year
													.toString()
													.substr(2, 2)}`,
											])}
										</p>
									</div>
								</>
							)}
						</>
					) : (
						<CircularProgress />
					)}
				</div>
			</div>
		</div>
	)
}

export default PaymentCard
