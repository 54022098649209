import './StationsMap.scss'
import React, { useState } from 'react'
import BEMHelper from 'react-bem-helper'
import { useConnect } from 'redux-bundler-hook'
import Button from '@material-ui/core/Button'

import useTranslations from '@src/hooks/useTranslations'

import MobileBottomContainer from '@src/components/MobileBottomContainer'
import StationMobileListItem from '@src/components/stations/StationMobileListItem'
import { SITE_TYPES } from '@src/bundles/site'
import { ReactComponent as ChevronDownIcon } from '@src/assets/icons/chevron_down.svg'
import { ReactComponent as ChevronUpIcon } from '@src/assets/icons/chevron_up.svg'

const bem = new BEMHelper('stations-mobile-list')

const StationsMobileList = ({
	stations = [],
	onSelected,
	selectedLocation,
	placeInfo,
	mapMoved,
}) => {
	const t = useTranslations()
	const [expanded, setExpanded] = useState(false)
	const { selectedStation, service } = useConnect(
		'selectSelectedStation',
		'selectService',
	)
	return (
		<MobileBottomContainer mediumHeight={expanded} smallHeight={!expanded}>
			<Button
				{...bem('toggle-button')}
				color='primary'
				onClick={() => setExpanded(!expanded)}
				fullWidth
			>
				{expanded ? <ChevronDownIcon /> : <ChevronUpIcon />}
			</Button>
			{placeInfo !== null &&
				placeInfo !== undefined &&
				!mapMoved &&
				placeInfo.place_area && (
					<h1 {...bem('list-area-title')}>
						{t(`kinto.area.title.${placeInfo.place_area}`)}
					</h1>
				)}
			{service === SITE_TYPES.FLEX && (
				<>
					<h3 {...bem('list-area-title')}>{t('kinto.flex.list.title')}</h3>
					<p {...bem('list-area-text')}>{t('kinto.flex.list.text')}</p>
				</>
			)}
			{!selectedStation &&
				stations.map((station, index) => (
					<StationMobileListItem
						key={`station-list.${index}`}
						selectedLocation={selectedLocation}
						station={station}
						onClick={onSelected}
					/>
				))}
			{selectedStation && (
				<StationMobileListItem
					station={selectedStation}
					selectedLocation={selectedLocation}
				/>
			)}
			{stations.length === 0 && !selectedStation && (
				<div {...bem('no-stations')}>
					{t('kinto.stations.no-stations-visable')}
				</div>
			)}
		</MobileBottomContainer>
	)
}

export default StationsMobileList
