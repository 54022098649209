import React from 'react'
import BEMHelper from 'react-bem-helper'
import { graphql, useStaticQuery } from 'gatsby'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import usePosition from '@src/hooks/usePosition'
import useTranslations from '@src/hooks/useTranslations'

import {
	distanceInMeterBetweenEarthCoordinates,
	readableDistance,
} from '@src/utils/calculations'

const bem = new BEMHelper('stations')
const query = graphql`
	query getStations {
		pimcore {
			getStationListing {
				edges {
					node {
						id
						RidecellID
						ImageGallery {
							image {
								fullpath(thumbnail: "station_list")
							}
						}
					}
				}
			}
		}
	}
`
const StationMapListItem = ({
	onClick = () => {},
	station = {},
	selectedLocation,
}) => {
	const t = useTranslations()
	const position = usePosition()
	const { pimcore } = useStaticQuery(query)
	const stations = pimcore.getStationListing.edges.map(({ node }) => node)

	const currentStation = stations.find((s) => s.RidecellID === station.id)
	const imagePath = currentStation
		? currentStation.ImageGallery &&
		  currentStation.ImageGallery.length > 0 &&
		  `${process.env.GATSBY_PIMCORE_BASE_URL}${currentStation.ImageGallery[0].image.fullpath}`
		: ''

	return (
		// eslint-disable-next-line jsx-a11y/click-events-have-key-events
		<div {...bem('list-item')} onClick={onClick} role='button' tabIndex={0}>
			<img
				{...bem('list-item-image')}
				src={imagePath}
				alt={t('kinto.station-image')}
			/>
			<div {...bem('list-item-container')}>
				<div {...bem('list-item-text-container')}>
					<h4>{station.name}</h4>
					<p>{station.location.display_name}</p>
				</div>
				<div {...bem('list-item-other-container')}>
					<h5 {...bem('distance')}>
						{selectedLocation
							? readableDistance(
									distanceInMeterBetweenEarthCoordinates(
										station.location.lat,
										station.location.lng,
										selectedLocation.geoData.geometry.location.lat(),
										selectedLocation.geoData.geometry.location.lng(),
									),
							  )
							: ''}
						{!selectedLocation && position && position.latitude
							? readableDistance(
									distanceInMeterBetweenEarthCoordinates(
										station.location.lat,
										station.location.lng,
										position.latitude,
										position.longitude,
									),
							  )
							: ''}
					</h5>
					<ChevronRightIcon fontSize='large' />
				</div>
			</div>
		</div>
	)
}

export default StationMapListItem
