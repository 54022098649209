import React from 'react'
import BEMHelper from 'react-bem-helper'

import Paper from '@material-ui/core/Paper'

import usePosition from '@src/hooks/usePosition'

import {
	distanceInMeterBetweenEarthCoordinates,
	readableDistance,
} from '@src/utils/calculations'

const bem = new BEMHelper('stations-mobile-list')

const StationMobileListItem = ({
	onClick = () => {},
	station = {},
	selectedLocation,
}) => {
	const position = usePosition()

	return (
		// eslint-disable-next-line jsx-a11y/click-events-have-key-events
		<Paper
			{...bem('item')}
			onClick={() => onClick(station)}
			role='button'
			tabIndex={0}
		>
			<div {...bem('item-container')}>
				<div {...bem('item-text-container')}>
					<h4>{station.name}</h4>
					<p>{station.location.display_name}</p>
				</div>
			</div>
			<div {...bem('item-other-container')}>
				<p>
					{selectedLocation
						? readableDistance(
								distanceInMeterBetweenEarthCoordinates(
									station.location.lat,
									station.location.lng,
									selectedLocation.geoData.geometry.location.lat(),
									selectedLocation.geoData.geometry.location.lng(),
								),
						  )
						: ''}
					{!selectedLocation && position && position.latitude
						? readableDistance(
								distanceInMeterBetweenEarthCoordinates(
									station.location.lat,
									station.location.lng,
									position.latitude,
									position.longitude,
								),
						  )
						: ''}
				</p>
			</div>
		</Paper>
	)
}

export default StationMobileListItem
