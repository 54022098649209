import './DatePicker.scss'
import React from 'react'
import Paper from '@material-ui/core/Paper'
import Divider from '@material-ui/core/Divider'
import BEMHelper from 'react-bem-helper'
import { ReactComponent as Calendar } from '@src/assets/icons/calendar.svg'
import { ReactComponent as Clock } from '@src/assets/icons/clock.svg'
import { format } from 'date-fns'
import svLocale from 'date-fns/locale/sv'

const bem = new BEMHelper('date-picker')

function DatePicker({
	dateActive,
	chosenDate,
	changeDate = () => {},
	date,
	timeActive,
	chosenTime,
	changeTime = () => {},
	label,
	checkout,
	disabled,
	flexHide,
}) {
	const formattedDate = getFormattedDate(date)
	const formattedTime = getFormattedTime(chosenTime)

	return (
		<Paper {...(flexHide ? { ...bem('', 'flexHide') } : { ...bem('') })}>
			<div {...bem('')}>
				<div
					{...(dateActive || timeActive
						? { ...bem('booking', 'section-active') }
						: checkout
						? { ...bem('booking', 'checkout') }
						: { ...bem('booking') })}
				>
					<button
						{...(dateActive && { ...bem('booking', 'active', 'left') })}
						{...(chosenDate && checkout
							? {
									...bem('booking', 'chosen', 'checkout'),
							  }
							: chosenDate && {
									...bem('booking', 'chosen'),
							  })}
						onClick={changeDate}
						disabled={(!chosenDate && !chosenTime) || disabled}
					>
						<Calendar />
						{chosenDate || dateActive ? (
							<p className='h5'>{chosenDate ? formattedDate : label}</p>
						) : (
							<p>{label}</p>
						)}
					</button>
					<Divider orientation='vertical' />
					<button
						{...(timeActive && { ...bem('booking', 'active', 'right') })}
						{...(chosenTime && checkout
							? {
									...bem('booking', 'chosen', 'checkout'),
							  }
							: chosenTime && {
									...bem('booking', 'chosen'),
							  })}
						onClick={changeTime}
						disabled={!chosenDate || disabled}
					>
						<Clock />
						<p className='h5'>{formattedTime}</p>
					</button>
				</div>
			</div>
		</Paper>
	)
}

export default DatePicker

function getFormattedDate(date) {
	if (date === null) return ''
	try {
		return format(new Date(date), 'dd MMM', { locale: svLocale })
	} catch (error) {
		return date
	}
}

function getFormattedTime(time) {
	if (time === null) return ''
	try {
		return format(new Date(time), 'HH:mm', { locale: svLocale })
	} catch (error) {
		return time
	}
}
