import React from 'react'
import BEMHelper from 'react-bem-helper'
import { useConnect } from 'redux-bundler-hook'
import { SITE_TYPES } from '@src/bundles/site'
import useTranslations from '@src/hooks/useTranslations'
import StationMapListItem from '@src/components/stations/StationMapListItem'

const bem = new BEMHelper('stations')

const StationsMapList = ({
	stations = [],
	selectedLocation,
	onSelected,
	placeInfo,
	mapMoved,
}) => {
	const t = useTranslations()
	const { selectedStation, service } = useConnect(
		'selectSelectedStation',
		'selectService',
	)

	return (
		<>
			{!selectedStation && (
				<div {...bem('list')}>
					{placeInfo !== null &&
						placeInfo !== undefined &&
						!mapMoved &&
						placeInfo.place_area && (
							<>
								<h1 {...bem('list-area-title')}>
									{t(`kinto.area.title.${placeInfo.place_area}`)}
								</h1>
								<p {...bem('list-area-text')}>
									{t(`kinto.area.text.${placeInfo.place_area}`)}
								</p>
							</>
						)}
					{service === SITE_TYPES.FLEX && (
						<>
							<h3 {...bem('list-area-title')}>{t('kinto.flex.list.title')}</h3>
							<p {...bem('list-area-text')}>{t('kinto.flex.list.text')}</p>
						</>
					)}
					{stations.map((station, index) => (
						<StationMapListItem
							key={`station-list.${index}`}
							selectedLocation={selectedLocation}
							station={station}
							onClick={() => {
								onSelected(station)
							}}
						/>
					))}
				</div>
			)}
			{selectedStation && (
				<div {...bem('list')}>
					<StationMapListItem
						station={selectedStation}
						selectedLocation={selectedLocation}
					/>
				</div>
			)}
			{stations.length === 0 && !selectedStation && (
				<p {...bem('no-stations')}>{t('kinto.stations.no-stations-visable')}</p>
			)}
		</>
	)
}

export default StationsMapList
