import './CheckoutRightColumn.scss'
import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import BEMHelper from 'react-bem-helper'
import useSetting from '@src/hooks/useSetting'
import { useConnect } from 'redux-bundler-hook'
import { SITE_TYPES } from '@src/bundles/site'

const bem = new BEMHelper('checkout-right-column')

const query = graphql`
	query getSnippet {
		allSnippet {
			edges {
				node {
					editables {
						name
						type
						value
					}
					name
				}
			}
		}
	}
`

const CheckoutRightColumn = () => {
	const getSetting = useSetting()
	const cdnHost = getSetting('CDNHost', '')
	const { allSnippet } = useStaticQuery(query)

	const { service } = useConnect('selectService')

	const snippetName =
		service === SITE_TYPES.FLEX ? 'flexCheckoutSnippet' : 'checkoutSnippet'

	const checkoutSnippet = allSnippet.edges.find(
		({ node }) => node.name === snippetName,
	)
	const snippetImage = checkoutSnippet.node.editables.find(
		({ type }) => type === 'image',
	)
	const snippetText = checkoutSnippet.node.editables.find(
		({ type }) => type === 'wysiwyg',
	)
	return (
		snippetImage.value &&
		snippetText.value && (
			<div {...bem('')}>
				<img {...bem('image')} src={`${cdnHost}${snippetImage.value}`} alt='' />
				<div
					{...bem('text')}
					dangerouslySetInnerHTML={{
						__html: snippetText.value,
					}}
				></div>
			</div>
		)
	)
}

export default CheckoutRightColumn
