import './pages/SelectBookingDates.scss'
import React, { useState, useEffect, useRef } from 'react'
import BEMHelper from 'react-bem-helper'
import { useConnect } from 'redux-bundler-hook'
import Paper from '@material-ui/core/Paper'
import TimePicker from '@src/components/TimePicker'
import DatePickerBooking from '@src/components/booking/DatePicker'
import CircularProgress from '@material-ui/core/CircularProgress'
import { DatePicker } from '@material-ui/pickers'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import { azureApiGatewayGet } from '@src/api/azureApiGateway/client'
import svLocale from 'date-fns/locale/sv'
import format from 'date-fns/format'
import DateFnsUtils from '@date-io/date-fns'
import useTranslations from '@src/hooks/useTranslations'
import { SITE_TYPES } from '@src/bundles/site'

const bem = new BEMHelper('booking')

const START_DATE = 'startDate'
const END_DATE = 'endDate'
const START_TIME = 'startTime'
const END_TIME = 'endTime'

export default function IntervalPicker({
	onStartChange = () => {},
	onIntervalChosen,
}) {
	const t = useTranslations()
	const {
		selectedStation,
		doSetInterval,
		startDate,
		startTime,
		endDate,
		endTime,
		doSetStartDate,
		doSetStartTime,
		doSetEndDate,
		doSetEndTime,
		doResetInterval,
		bookingSiteType,
		service,
	} = useConnect(
		'selectSelectedStation',
		'doSetInterval',
		'selectStartDate',
		'selectStartTime',
		'selectEndDate',
		'selectEndTime',
		'doSetStartDate',
		'doSetStartTime',
		'doSetEndDate',
		'doSetEndTime',
		'doResetInterval',
		'selectBookingSiteType',
		'selectService',
	)

	const [hasChanged, setHasChanged] = useState(false)
	const [loading, setLoading] = useState(false)
	const [firstAvailableStartDate, setFirstAvailableStartDate] = useState([])
	const [lastAvailableStartDate, setLastAvailableStartDate] = useState([])
	const [availableStartTimes, setAvailableStartTimes] = useState([])
	const [firstAvailableEndDate, setFirstAvailableEndDate] = useState([])
	const [lastAvailableEndDate, setLastAvailableEndDate] = useState([])
	const [availableEndTimes, setAvailableEndTimes] = useState([])

	const [activeState, setActiveState] = useState(START_DATE)

	let startDateRef = useRef()
	let endDateRef = useRef()

	let cancelStartDates = {}
	let cancelStartTimes = {}
	let cancelEndDates = {}
	let cancelEndTimes = {}

	async function fetchAvailableStartDates() {
		setLoading(true)
		try {
			const response = await azureApiGatewayGet(
				`booking/${selectedStation.id}/station_start_dates`,
				{
					rental_mode:
						bookingSiteType === SITE_TYPES.BUSINESS ? 'BUSINESS' : 'PERSONAL',
				},
				{ cancel: cancelStartDates, useToken: true },
			)
			const sortedDates = response.data.dates.sort()
			setFirstAvailableStartDate(sortedDates[0])
			setLastAvailableStartDate(sortedDates[sortedDates.length - 1])
			setLoading(false)
			return sortedDates
		} catch (error) {}
	}

	async function fetchAvailableStartTimes(startDate) {
		try {
			setLoading(true)
			const response = await azureApiGatewayGet(
				`booking/${selectedStation.id}/station_start_times`,
				{
					start_date: startDate,
					rental_mode:
						bookingSiteType === SITE_TYPES.BUSINESS ? 'BUSINESS' : 'PERSONAL',
				},
				{ cancel: cancelStartTimes, useToken: true },
			)
			setAvailableStartTimes(response.data.times)
			setLoading(false)
			return response.data.times
		} catch (error) {}
	}

	async function fetchAvailableEndDates(newStartTime) {
		try {
			setLoading(true)
			const response = await azureApiGatewayGet(
				`booking/${selectedStation.id}/station_end_dates?start_datetime=${newStartTime}`, // start_datetime is added here to avoid URL encoding in axios, APIGateway can't handle it
				{
					rental_mode:
						bookingSiteType === SITE_TYPES.BUSINESS ? 'BUSINESS' : 'PERSONAL',
				},
				{ cancel: cancelEndDates, useToken: true },
			)
			const sortedDates = response.data.dates.sort()
			setFirstAvailableEndDate(sortedDates[0])
			setLastAvailableEndDate(sortedDates[sortedDates.length - 1])
			setLoading(false)
			return sortedDates
		} catch (error) {}
	}

	async function fetchAvailableEndTimes(startDateTime, endDate) {
		try {
			setLoading(true)
			const response = await azureApiGatewayGet(
				`booking/${selectedStation.id}/station_end_times?start_datetime=${startDateTime}`, // start_datetime are added here to avoid URL encoding in axios, APIGateway can't handle it
				{
					end_date: endDate,
					rental_mode:
						bookingSiteType === SITE_TYPES.BUSINESS ? 'BUSINESS' : 'PERSONAL',
				},
				{ cancel: cancelEndTimes, useToken: true },
			)
			setAvailableEndTimes(response.data.times)
			setLoading(false)
			return response.data.times
		} catch (error) {}
	}

	useEffect(() => {
		async function onSetup() {
			let availableEndDates = []
			let _availableStartTimes = []
			let _availableEndTimes = []

			if (startDate) {
				startDateRef.current = startDate
			}
			if (endDate) {
				endDateRef.current = endDate
			}

			const availableStartDates = await fetchAvailableStartDates()
			if (startDate !== null) {
				if (!availableStartDates.includes(startDate)) {
					doResetInterval()
					return
				}

				_availableStartTimes = await fetchAvailableStartTimes(startDate)
			}
			if (startTime !== null) {
				if (!_availableStartTimes.includes(startTime)) {
					doSetStartTime(null)
					doSetEndDate(null)
					doSetEndTime(null)
					return
				}
				availableEndDates = await fetchAvailableEndDates(startTime)
			}
			if (endDate !== null) {
				if (!availableEndDates.includes(endDate)) {
					doSetEndDate(null)
					doSetEndTime(null)
					return
				}
				_availableEndTimes = await fetchAvailableEndTimes(startTime, endDate)
			}
			if (endTime !== null) {
				if (!_availableEndTimes.includes(endTime)) {
					doSetEndTime(null)
					return
				}
			}

			if (endTime !== null) {
				setActiveState(null)
				onIntervalChosen()
			} else if (endDate !== null) {
				setActiveState(END_TIME)
			} else if (startTime !== null) {
				setActiveState(END_DATE)
			} else if (startDate !== null) {
				setActiveState(START_TIME)
			}
			doSetInterval({ startDate, startTime, endDate, endTime })
			return () => {
				cancelStartDates.cancel && cancelStartDates.cancel()
				cancelStartTimes.cancel && cancelStartTimes.cancel()
				cancelEndDates.cancel && cancelEndDates.cancel()
				cancelEndTimes.cancel && cancelEndTimes.cancel()
			}
		}

		onSetup()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	const startChange = () => {
		onStartChange()
		setActiveState(null)
	}

	const changeStartDate = () => {
		startChange()
		doResetInterval()
		setActiveState(START_DATE)
	}

	const changeStartTime = () => {
		startChange()
		doSetStartTime()
		doSetEndDate()
		doSetEndTime()
		setActiveState(START_TIME)
	}

	const changeEndDate = () => {
		startChange()
		doSetEndDate()
		doSetEndTime()
		setActiveState(END_DATE)
	}

	const changeEndTime = () => {
		startChange()
		doSetEndTime()
		setActiveState(END_TIME)
	}

	const onChangeStartDate = (newStartDate) => {
		if (!newStartDate) {
			return
		}
		const formattedStartDate = format(newStartDate, 'yyyy-MM-dd')
		if (hasChanged) {
			doSetStartDate(formattedStartDate)
			fetchAvailableStartTimes(formattedStartDate)
			startDateRef.current = format(newStartDate, 'd MMM', {
				locale: svLocale,
			})
			setActiveState(START_TIME)
			doSetStartDate(formattedStartDate)
		} else {
			setHasChanged(true)
		}
	}

	const onChangeStartTime = async (newStartTime) => {
		await fetchAvailableEndDates(newStartTime)
		doSetStartTime(newStartTime)
		if (service !== SITE_TYPES.FLEX) {
			setActiveState(END_DATE)
		} else {
			setActiveState(null)
		}
	}

	const onChangeEndDate = (newEndDate) => {
		if (!newEndDate) {
			return
		}
		const formattedEndDate = format(newEndDate, 'yyyy-MM-dd')
		if (hasChanged && service !== SITE_TYPES.FLEX) {
			fetchAvailableEndTimes(startTime, formattedEndDate)
			endDateRef.current = format(newEndDate, 'd MMM', {
				locale: svLocale,
			})
			setActiveState(END_TIME)
			doSetEndDate(formattedEndDate)
		} else {
			setHasChanged(true)
		}
	}

	const onChangeEndTime = (newEndTime) => {
		doSetEndTime(newEndTime)
		setActiveState(null)
		doSetInterval({ startDate, startTime, endDate, endTime: newEndTime })
		onIntervalChosen()
	}

	useEffect(() => {
		if (
			service === SITE_TYPES.FLEX &&
			startTime !== null &&
			startTime !== undefined &&
			firstAvailableEndDate.length > 0
		) {
			const newEndDate = new Date(firstAvailableEndDate)
			const formattedEndDate = format(newEndDate, 'yyyy-MM-dd')
			fetchAvailableEndTimes(startTime, formattedEndDate)
			endDateRef.current = format(newEndDate, 'd MMM', {
				locale: svLocale,
			})
			doSetEndDate(formattedEndDate)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [startTime])

	useEffect(() => {
		if (service === SITE_TYPES.FLEX && availableEndTimes.length > 0) {
			doSetEndTime(availableEndTimes[0])
			setActiveState(null)
			doSetInterval({
				startDate,
				startTime,
				endDate,
				endTime: availableEndTimes[0],
			})
			onIntervalChosen()
			setAvailableEndTimes([])
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [endDate, availableEndTimes])

	// The DatePicker from @material-ui/pickers automatically selects the first available date.
	// To keep the datepicker open we do this hack to ignore the first change
	useEffect(() => {
		setHasChanged(false)
		if (loading === false) {
			const timer = setTimeout(() => {
				setHasChanged(true)
			}, 200)
			return () => clearTimeout(timer)
		}
	}, [loading])

	const getActivePanel = () => {
		switch (activeState) {
			case START_DATE:
				return (
					<MuiPickersUtilsProvider utils={LocalizedUtils} locale={svLocale}>
						<DatePicker
							autoOk
							orientation='landscape'
							variant='static'
							value={firstAvailableStartDate}
							onChange={onChangeStartDate}
							disableToolbar
							disablePast
							minDate={firstAvailableStartDate}
							maxDate={lastAvailableStartDate}
						/>
					</MuiPickersUtilsProvider>
				)
			case END_DATE:
				return (
					<MuiPickersUtilsProvider utils={LocalizedUtils} locale={svLocale}>
						<DatePicker
							autoOk
							orientation='landscape'
							variant='static'
							value={endDate}
							onChange={onChangeEndDate}
							disableToolbar
							minDate={firstAvailableEndDate}
							maxDate={lastAvailableEndDate}
							initialFocusedDate={startDate}
						/>
					</MuiPickersUtilsProvider>
				)
			case START_TIME:
				return (
					<TimePicker
						handleClick={onChangeStartTime}
						text={t('kinto.booking.start-time')}
						availableTimes={availableStartTimes}
					/>
				)
			case END_TIME:
				return (
					<TimePicker
						handleClick={onChangeEndTime}
						text={t('kinto.booking.end-time')}
						availableTimes={availableEndTimes}
						endTime
					/>
				)
			default:
				break
		}
	}

	return (
		<>
			<div
				{...(service === SITE_TYPES.FLEX
					? { ...bem('booking-container', 'flex') }
					: { ...bem('booking-container') })}
			>
				<DatePickerBooking
					dateActive={activeState === START_DATE}
					chosenDate={startDate}
					changeDate={changeStartDate}
					date={startDateRef.current}
					timeActive={activeState === START_TIME}
					chosenTime={startTime}
					changeTime={changeStartTime}
					label={t('kinto.booking.pickup')}
				/>
				<DatePickerBooking
					dateActive={activeState === END_DATE}
					chosenDate={endDate}
					changeDate={changeEndDate}
					date={endDateRef.current}
					timeActive={activeState === END_TIME}
					chosenTime={endTime}
					changeTime={changeEndTime}
					label={t('kinto.booking.leave')}
					flexHide={
						service === SITE_TYPES.FLEX &&
						(activeState === START_DATE || activeState === START_TIME)
					}
					disabled={service === SITE_TYPES.FLEX}
				/>
			</div>
			{activeState && (
				<div
					{...(activeState === END_TIME
						? { ...bem('picker-container', 'time', 'left') }
						: activeState === END_DATE
						? { ...bem('picker-container', 'date') }
						: activeState === START_TIME || activeState === END_TIME
						? { ...bem('picker-container', 'time') }
						: { ...bem('picker-container') })}
				>
					<Paper>{loading ? <CircularProgress /> : getActivePanel()}</Paper>
				</div>
			)}
		</>
	)
}

class LocalizedUtils extends DateFnsUtils {
	getDatePickerHeaderText(date) {
		return format(date, 'd MMMM yyyy', { locale: this.locale })
	}
}
