import './BookingCards.scss'
import './EliminatingExcessCard.scss'
import React, { useState, useEffect } from 'react'
import { useConnect } from 'redux-bundler-hook'
import { azureApiGatewayGet } from '@src/api/azureApiGateway/client'
import { get } from 'lodash'
import BEMHelper from 'react-bem-helper'
import RadioGroup from '@material-ui/core/RadioGroup'
import Radio from '@material-ui/core/Radio'
import Checkbox from '@material-ui/core/Checkbox'
import FormControl from '@material-ui/core/FormControl'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import useTranslations from '@src/hooks/useTranslations'
import { googleAnalytics } from '@src/api/googleanalytics/googleAnalytics'
import Modal from '@src/components/Modal'
import InformationBox from '@src/components/InformationBox'
import { ReactComponent as Calendar } from '@src/assets/icons/calendar-x.svg'
import { EmbeddableContentPage } from '@src/templates/contentPage'
import usePageContextData from '@src/utils/brickresolvers/usePageContextData'
import CircularProgress from '@material-ui/core/CircularProgress'
import ErrorModal from '@src/components/ErrorModal'
import { localizedUrl } from '@src/utils/navigate'
import { SITE_TYPES } from '@src/bundles/site'

const bem = new BEMHelper('booking-cards')

const EliminatingExcessCard = () => {
	const {
		doSetInsurance,
		doSetInsuranceChecked,
		bookingInfo: { startTime, endTime, serviceId },
		selectedCar,
		service,
	} = useConnect(
		'doSetInsurance',
		'doSetInsuranceChecked',
		'selectBookingInfo',
		'selectSelectedCar',
		'selectService',
	)

	const t = useTranslations()
	const [errorMessage, setErrorMessage] = useState(null)
	const [checkboxChecked, setCheckboxChecked] = useState(false)
	const [chosenInsurance, setChosenInsurance] = useState()
	const [loading, setLoading] = useState(false)
	const [addons, setAddons] = useState()
	const [value, setValue] = useState()

	useEffect(() => {
		async function fetchAddons() {
			try {
				setLoading(true)
				const response = await azureApiGatewayGet(
					`booking/available_addons?service_id=${serviceId}&vehicle_type_id=${selectedCar.id}&start_datetime=${startTime}&end_datetime=${endTime}`,
					{},
					{ useToken: true },
				)
				setAddons(response.data)
				setValue(
					response.data.available_addons.length > 0
						? response.data.available_addons[0].display_name
						: 'noInsurance',
				)
				setLoading(false)
			} catch (error) {
				setLoading(false)
				if (get(error, 'response.data.messages')) {
					setErrorMessage(error.response.data.messages)
				}
			}
		}
		fetchAddons()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	useEffect(() => {
		const checkChosenInsurance =
			addons !== undefined &&
			addons.available_addons.find(({ display_name }) => display_name === value)
		setChosenInsurance(
			checkChosenInsurance === undefined ? value : checkChosenInsurance,
		)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [value])

	useEffect(() => {
		doSetInsurance(chosenInsurance)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [chosenInsurance])

	useEffect(() => {
		doSetInsuranceChecked(checkboxChecked)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [checkboxChecked])

	const handleChange = (event) => {
		setValue(event.target.value)
		setCheckboxChecked(false)
	}

	const handleChecked = (event) => {
		setCheckboxChecked(event.target.checked)
	}

	return (
		addons !== undefined &&
		addons.available_addons.length > 0 && (
			<div {...bem('', 'no-flex no-padding exess')} id={'insurance'}>
				<div {...bem('heading')}>
					<h2 className='h3'>
						{service === SITE_TYPES.FLEX
							? t('kinto.checkout.flex.insurance.title')
							: t('kinto.checkout.insurance.title')}
					</h2>
				</div>
				{errorMessage && (
					<ErrorModal onClose={() => setErrorMessage(null)}>
						{errorMessage}
					</ErrorModal>
				)}
				<div {...bem('row')}>
					<div {...bem('', 'insurance')}>
						{loading ? (
							<CircularProgress />
						) : (
							<FormControl component='fieldset'>
								<RadioGroup
									aria-label='insurance'
									name='insurance'
									value={value}
									onChange={handleChange}
								>
									{addons !== undefined &&
										addons.available_addons.map((addon) => {
											return (
												<>
													<FormControlLabel
														{...bem('radio')}
														value={addon.display_name}
														control={<Radio color='primary' />}
														label={
															<>
																<span>{addon.display_name}</span>{' '}
																<span className='h6'>
																	+ {addon.price.addon_price_display}
																</span>
															</>
														}
														key={addon.display_name}
													/>
													{addon.is_terms_condition_mandatory &&
														value === addon.display_name && (
															<FormControlLabel
																control={
																	<Checkbox
																		checked={checkboxChecked}
																		onChange={handleChecked}
																		color='primary'
																	/>
																}
																label={
																	chosenInsurance &&
																	chosenInsurance.terms_condition_url && (
																		<p>
																			{t('kinto.checkout.insurance.agree', [
																				<EliminatingExcessModal
																					key={'1'}
																					textKey='kinto.checkout.insurance.agree.link'
																					value={chosenInsurance.terms_condition_url.replace(
																						process.env.GATSBY_WWW_BASE_URL,
																						'',
																					)}
																				/>,
																			])}
																		</p>
																	)
																}
															/>
														)}
												</>
											)
										})}

									<FormControlLabel
										{...bem('radio')}
										value='noInsurance'
										control={<Radio color='primary' />}
										label={
											<>
												<span>{t('kinto.checkout.insurance.none')}</span>{' '}
												<span className='h6'>
													{t('kinto.checkout.insurance.none.price')}
												</span>
											</>
										}
									/>
								</RadioGroup>
							</FormControl>
						)}
						{value === 'noInsurance' && (
							<InformationBox
								color='red'
								text={t('kinto.checkout.insurance.none.information')}
								icon={<Calendar />}
								checkout={true}
							/>
						)}
					</div>
				</div>
			</div>
		)
	)
}

export default EliminatingExcessCard

function EliminatingExcessModal({ textKey, value }) {
	const t = useTranslations()
	const { language, service } = useConnect('selectLanguage', 'selectService')
	const [showModal, setShowModal] = useState(false)

	useEffect(() => {
		googleAnalytics.pushVirtualPageView(
			'/virtual/stations/eliminatingexcesscard',
			'EliminatingExcessModal',
		)
	}, [])

	return (
		<>
			<button className='button' onClick={() => setShowModal(true)}>
				{t(textKey)}
			</button>
			<Modal
				open={showModal}
				handleClose={() => setShowModal(false)}
				xButtonAbsolute
				noMargin
			>
				<EmbeddableContentPage
					pageContext={usePageContextData({
						pageName: localizedUrl(value, language, service),
					})}
				></EmbeddableContentPage>
			</Modal>
		</>
	)
}
