import './StationCard.scss'
import React from 'react'
import BEMHelper from 'react-bem-helper'
import Map from '@src/components/Map'
import CheckoutRightColumn from '@src/components/booking/CheckoutRightColumn'

const bem = new BEMHelper('booking-cards')

const StationCard = ({ station = { location: {} }, isDesktop }) => {
	return (
		<div {...bem('', 'no-padding')}>
			<div {...bem('row')}>
				<div {...bem('map')}>
					<Map width={180} height={130} stations={[station]} disabled loaded />
				</div>
				<div {...bem('station-info')}>
					<div {...bem('station-info', 'title')}>
						<p className='h4'>{station.name}</p>
					</div>
					<p className='small'>{station.location.display_name}</p>
				</div>
			</div>
			{isDesktop && <CheckoutRightColumn />}
		</div>
	)
}

export default StationCard
