import './MobileBottomContainer.scss'
import React from 'react'
import BEMHelper from 'react-bem-helper'

const bem = new BEMHelper('mobilebottomcontainer')

/**
 *
 * @param {smallHeight} 	Shows 1 items
 * @param {mediumHeight} 	Shows 3 items
 */
const MobileBottomContainer = ({ children, mediumHeight, smallHeight }) => {
	return (
		<div
			{...bem('')}
			{...(mediumHeight && { ...bem('', 'medium') })}
			{...(smallHeight && { ...bem('', 'small') })}
		>
			<div {...bem('content')}>{children}</div>
		</div>
	)
}

export default MobileBottomContainer
