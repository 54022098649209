import './CarCard.scss'
import React from 'react'
import BEMHelper from 'react-bem-helper'
import { get } from 'lodash'
import useSetting from '@src/hooks/useSetting'
import useTranslations from '@src/hooks/useTranslations'
import Button from '@material-ui/core/Button'
import { useConnect } from 'redux-bundler-hook'
import { SITE_TYPES } from '@src/bundles/site'
import Link from '@src/components/Link'

const bem = new BEMHelper('car-card')

const CarCard = ({ car, withButton, onSelect }) => {
	const t = useTranslations()
	const {
		allVehicleTypes,
		service,
		bookingSiteType,
		isCustomerLoggedIn,
		membership,
		language,
	} = useConnect(
		'selectAllVehicleTypes',
		'selectService',
		'selectBookingSiteType',
		'selectIsCustomerLoggedIn',
		'selectMembership',
		'selectLanguage',
	)
	const vehicleType =
		allVehicleTypes.find(
			(vehicleType) =>
				vehicleType.RidecellID === (car.RidecellID ? car.RidecellID : car.id),
		) || car
	const getSetting = useSetting()
	const cdnHost = getSetting('CDNHost', '')
	const bookingLinkSetting = getSetting('bookingLink', null)
	const bookingLinkNotLoggedInSetting = getSetting(
		'bookingLinkNotLoggedIn',
		null,
	)
	const bookingLinkMembershipsNotShow = getSetting(
		'bookingLinkMembershipsNotShow',
		null,
	).split(',')

	let bookingLink = !isCustomerLoggedIn
		? bookingLinkNotLoggedInSetting
		: (isCustomerLoggedIn && membership == null) ||
		  (isCustomerLoggedIn &&
				membership !== null &&
				!bookingLinkMembershipsNotShow.includes(membership.toString()))
		? bookingLinkSetting
		: null

	const fullpath = get(vehicleType, 'MainImage.fullpath')

	const handleBookingLinkClick = (e) => {
		e.stopPropagation()
	}

	return (
		<div {...bem('')}>
			<div {...bem('container')}>
				<img
					{...bem('image')}
					src={`${cdnHost}${fullpath}`}
					alt={vehicleType.Brand}
				/>
				<div {...bem('info-container')}>
					<div {...bem('info')}>
						<p className='h5'>{vehicleType.Brand}</p>
					</div>
					<div {...bem('price')}>
						<p className='h5'>
							{car.price
								? t('kinto.booking.price', [car.price.amount])
								: service === SITE_TYPES.FLEX &&
								  bookingSiteType === SITE_TYPES.PRIVATE
								? vehicleType.FlexPrice
								: service === SITE_TYPES.FLEX &&
								  bookingSiteType === SITE_TYPES.BUSINESS &&
								  vehicleType.FlexPriceBusiness}
						</p>
						<p className='small'>
							{service === SITE_TYPES.FLEX
								? vehicleType.FlexPriceExtraText
								: car.price && bookingSiteType === SITE_TYPES.PRIVATE
								? t('kinto.booking.ratePerKilometerShort', [
										vehicleType.RatePerKilometer,
								  ])
								: car.price &&
								  bookingSiteType === SITE_TYPES.BUSINESS &&
								  t('kinto.booking.ratePerKilometerShort', [
										vehicleType.RatePerKilometerBusiness,
								  ])}
						</p>
					</div>
				</div>
			</div>
			{car.price &&
				service === SITE_TYPES.SHARE &&
				bookingSiteType === SITE_TYPES.PRIVATE &&
				language === 'sv' &&
				bookingLink !== null &&
				!withButton && (
					<div {...bem('link-container')}>
						<span className='small'>{t('kinto.booking.link.text')}</span>
						<Link {...bem('link')} to={bookingLink} onClick={handleBookingLinkClick}>
							<span className='h5'>{t('kinto.booking.link')}</span>
						</Link>
					</div>
				)}
			{withButton && (
				<div {...bem('button-container')}>
					<Button
						aria-label={t('kinto.booking.select-car')}
						variant='outlined'
						color='primary'
						fullWidth
						onClick={() => onSelect(car)}
					>
						{t('kinto.booking.select-car')}
					</Button>
				</div>
			)}
		</div>
	)
}

export default CarCard
