import './CarCardCheckout.scss'
import React from 'react'
import BEMHelper from 'react-bem-helper'
import { get } from 'lodash'
import useSetting from '@src/hooks/useSetting'
import useTranslations from '@src/hooks/useTranslations'
import { useConnect } from 'redux-bundler-hook'

const bem = new BEMHelper('booking-cards')

const CarCardCheckout = ({ car }) => {
	const t = useTranslations()
	const { allVehicleTypes } = useConnect('selectAllVehicleTypes')
	const vehicleType =
		allVehicleTypes.find(
			(vehicleType) =>
				vehicleType.RidecellID === (car.RidecellID ? car.RidecellID : car.id),
		) || car
	const getSetting = useSetting()
	const cdnHost = getSetting('CDNHost', '')
	const fullpath = get(vehicleType, 'MainImage.fullpath')

	return (
		<div {...bem('', 'no-padding')}>
			<div {...bem('row')}>
				<div {...bem('car-image')}>
					<img
						{...bem('thumb-image')}
						src={`${cdnHost}${fullpath}`}
						alt={vehicleType.Brand}
					/>
				</div>
				<div {...bem('info', 'title')}>
					<p className='h4'>{vehicleType.Brand}</p>
					{vehicleType.Size && (
						<p className='small'>
							{t(`kinto.carattribute-${vehicleType.Size}`)}
						</p>
					)}
				</div>
			</div>
		</div>
	)
}

export default CarCardCheckout
