import './SelectBookingDates.scss'
import React, { useState, useEffect } from 'react'
import BEMHelper from 'react-bem-helper'
import { useConnect } from 'redux-bundler-hook'
import Paper from '@material-ui/core/Paper'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import useTranslations from '@src/hooks/useTranslations'
import { googleAnalytics } from '@src/api/googleanalytics/googleAnalytics'
import CarCard from '@src/components/booking/CarCard'
import IntervalPicker from '@src/components/booking/IntervalPicker'
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore'
import { azureApiGatewayGet } from '@src/api/azureApiGateway/client'
import {
	distanceInMeterBetweenEarthCoordinates,
	readableDistance,
} from '@src/utils/calculations'
import usePosition from '@src/hooks/usePosition'
import events, { EVENT_SHOW_LOGIN_MODAL } from '@src/utils/events'
import { handleClickFinishRegistration } from '@src/components/account/MissingInformationModal'
import useCustomerInfo from '@src/hooks/useCustomerInfo'
import customer from '@src/bundles/customer'
import { navigate } from 'gatsby'
import { EmbeddableContentPage } from '@src/templates/contentPage'
import usePageContextData from '@src/utils/brickresolvers/usePageContextData'
import Modal from '@src/components/Modal'
import InformationBox from '@src/components/InformationBox'
import { BOOKING_STEPS } from '@src/components/stations/StationsMap'
import useSetting from '@src/hooks/useSetting'
import { SITE_TYPES } from '@src/bundles/site'
import { localizedUrl } from '@src/utils/navigate'

const bem = new BEMHelper('booking')

const SelectBookingDates = ({ setNextStep }) => {
	const {
		selectedCar,
		isCustomerLoggedIn,
		availableCars,
		isSelectedCarAvailable,
		selectedStation,
		doSetBookingSelectedCar,
		doResetBookingSelectedCar,
		startTime,
		endTime,
		doSelectStation,
		isMissingInformation,
		doFetchAvailabelVehicles,
		bookingLoading,
		isMissingInformationBusiness,
		bookingSiteType,
		language,
		businessCustomer,
		service,
		doResetBookingInfo,
		siteType,
		membership,
		doSetReturnToBookingAfterRegistration,
	} = useConnect(
		'selectSelectedCar',
		'selectIsCustomerLoggedIn',
		'selectAvailableCars',
		'selectIsSelectedCarAvailable',
		'selectSelectedStation',
		'doSetBookingSelectedCar',
		'doResetBookingSelectedCar',
		'selectStartTime',
		'selectEndTime',
		'doSelectStation',
		'selectIsMissingInformation',
		'doFetchAvailabelVehicles',
		'selectBookingLoading',
		'selectIsMissingInformationBusiness',
		'selectBookingSiteType',
		'selectLanguage',
		'selectBusinessCustomer',
		'selectService',
		'doResetBookingInfo',
		'selectSiteType',
		'selectMembership',
		'doSetReturnToBookingAfterRegistration',
	)
	const t = useTranslations()
	const [bookingActive, setBookingActive] = useState(true)
	const [closestStations, setClosestStations] = useState([])
	const [loading, setLoading] = useState(false)
	const [isModalOpen, setIsModalOpen] = useState(false)
	const position = usePosition()
	const getSetting = useSetting()
	const numberOfClosestStations = parseInt(getSetting('closestStations'))
	const flexBookLink = getSetting('flexBookCarLink', null)
	const flexBusinessBookLink = getSetting('flexBusinessBookCarLink', null)

	useEffect(() => {
		googleAnalytics.pushVirtualPageView(
			'/virtual/stations/selectbookingdates/' + selectedCar.id,
			'Booking ' + selectedCar.name,
		)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	const customerInfo = useCustomerInfo()

	useEffect(() => {
		let cancel
		async function getClosestStations() {
			setLoading(true)
			const stationResponse = isCustomerLoggedIn
				? await azureApiGatewayGet('station', null, { cancel })
				: await azureApiGatewayGet('visitor_station', null, { cancel })
			const stations =
				stationResponse.data && stationResponse.data.stations
					? stationResponse.data.stations
					: []
			const stationsSortedByDistance = stations
				.filter((station) => station.id !== selectedStation.id)
				.sort((a, b) => {
					const distA = distanceInMeterBetweenEarthCoordinates(
						selectedStation.location.lat,
						selectedStation.location.lng,
						a.location.lat,
						a.location.lng,
					)
					const distB = distanceInMeterBetweenEarthCoordinates(
						selectedStation.location.lat,
						selectedStation.location.lng,
						b.location.lat,
						b.location.lng,
					)
					return distA < distB ? -1 : 1
				})
				.slice(0, numberOfClosestStations)

			const stationsWithAvailableVehicles = stationsSortedByDistance.map(
				async (station) => {
					try {
						const response = isCustomerLoggedIn
							? await azureApiGatewayGet(
									`booking/${station.id}/station_available_vehicle_types?start_datetime=${startTime}&end_datetime=${endTime}`, // start_datetime and end_datetime is added here to avoid URL encoding in axios, APIGateway can't handle it
									{
										rental_mode:
											bookingSiteType === SITE_TYPES.BUSINESS
												? 'BUSINESS'
												: 'PERSONAL',
									},
									{ useToken: true, cancel },
							  )
							: await azureApiGatewayGet(
									`booking/${station.id}/station_visitor_available_vehicle_types?start_datetime=${startTime}&end_datetime=${endTime}`, // start_datetime and end_datetime is added here to avoid URL encoding in axios, APIGateway can't handle it
									{ rental_mode: 'PERSONAL' },
									{ cancel },
							  )
						const availableVehicles = response.data.vehicle_types
						return { station, availableVehicles }
					} catch (error) {
						return { station, availableVehicles: [] }
					}
				},
			)

			// TODO optimize to not call for all stations
			const topThree = (await Promise.all(stationsWithAvailableVehicles))
				.filter(({ availableVehicles }) => {
					return availableVehicles.length > 0
				})
				.slice(0, 3)
			setClosestStations(topThree)
			setLoading(false)
		}
		!bookingActive &&
			!isSelectedCarAvailable &&
			service !== SITE_TYPES.FLEX &&
			getClosestStations()
		return () => {
			cancel && cancel()
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isSelectedCarAvailable, selectedStation, bookingActive])

	const triggerLogin = () => {
		events.emit(EVENT_SHOW_LOGIN_MODAL, {
			callback: (customerInfo) => {
				const isMissingInformation = customer.selectIsMissingInformation({
					customer: customerInfo,
				})
				if (isMissingInformation) {
					doFetchAvailabelVehicles()
				} else {
					setNextStep(BOOKING_STEPS.CHECKOUT)
				}
			},
			returnToBookingAfterRegistration: true,
		})
	}

	let carPath = ''
	if (
		selectedCar.ModalDocumentLink != null &&
		selectedCar.ModalDocumentLink.path
	) {
		carPath = selectedCar.ModalDocumentLink.path
	}

	const carPageContextData = usePageContextData({
		pageName: localizedUrl(carPath, language, service),
	})

	const clickBack = () => {
		if (service === SITE_TYPES.FLEX) {
			if (siteType === SITE_TYPES.PRIVATE) {
				navigate(localizedUrl(flexBookLink, language, service))
			} else {
				navigate(localizedUrl(flexBusinessBookLink, language, service))
			}
			doResetBookingInfo()
		} else {
			doResetBookingSelectedCar()
			setNextStep(BOOKING_STEPS.SELECT_CAR)
		}
	}

	return (
		<div {...bem('chat-bottom-margin')}>
			<div {...bem('top-container', 'flex')}>
				<button
					{...bem('back-button')}
					onClick={() => {
						clickBack()
					}}
				>
					<NavigateBeforeIcon />{' '}
					<p>
						{service === SITE_TYPES.FLEX
							? t('kinto.booking.flex.back')
							: t('kinto.booking.back')}
					</p>
				</button>
				<h1 className='h2'>
					{service === SITE_TYPES.FLEX
						? t('kinto.booking.flex')
						: bookingSiteType === SITE_TYPES.BUSINESS
						? t('kinto.booking.booking.business')
						: t('kinto.booking.booking')}
				</h1>
			</div>
			<div {...bem('sub-title')}>
				<div {...bem('sub-title', 'top')}>
					<h2 className='h4'>{t('kinto.booking.when')}</h2>
					{service === SITE_TYPES.SHARE && (
						<div>
							{isCustomerLoggedIn ? (
								<p className='smallBold'>
									{t(`kinto.membership${membership == null ? 0 : membership}`)}
								</p>
							) : (
								<p className='smallBold'>{t(`kinto.membership-none`)}</p>
							)}
						</div>
					)}
				</div>
				{service === SITE_TYPES.FLEX && (
					<p {...bem('sub-title', 'flex-extra')}>
						{t('kinto.booking.flex.when-extra')}
					</p>
				)}
			</div>
			<div // eslint-disable-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
				{...(carPath ? bem('car-card', 'modal') : bem('car-card'))}
				onClick={() => setIsModalOpen(true)}
				onKeyPress={() => setIsModalOpen(true)}
				role='button'
				tabIndex={0}
			>
				<CarPaper car={selectedCar} />
			</div>
			{carPath && (
				<Modal
					open={isModalOpen}
					handleClose={() => setIsModalOpen(false)}
					xButtonAbsolute
					noMargin
				>
					<EmbeddableContentPage
						pageContext={carPageContextData}
					></EmbeddableContentPage>
				</Modal>
			)}

			<IntervalPicker
				onStartChange={() => {
					setBookingActive(true)
				}}
				onIntervalChosen={() => {
					setBookingActive(false)
				}}
			/>
			{!bookingActive &&
				(isSelectedCarAvailable ? (
					<>
						<InformationBox color='green' text={t('kinto.booking.available')} />
						{service === SITE_TYPES.FLEX && t('kinto.booking.flex.available')}
						<div {...bem('buttons-container')}>
							{isCustomerLoggedIn ? (
								(!businessCustomer && isMissingInformation === true) ||
								(businessCustomer && isMissingInformationBusiness === true) ? (
									<Button
										aria-label={t('kinto.my-page.finish-registration')}
										variant='contained'
										color='primary'
										onClick={() => {
											doSetReturnToBookingAfterRegistration(true)
											handleClickFinishRegistration(customerInfo)
										}}
									>
										{t('kinto.my-page.finish-registration')}
									</Button>
								) : (
									<Button
										aria-label={t('kinto.booking.to-checkout')}
										variant='contained'
										color='primary'
										fullWidth
										onClick={() => setNextStep(BOOKING_STEPS.CHECKOUT)}
									>
										{t('kinto.booking.to-checkout')}
									</Button>
								)
							) : (
								<>
									<Button
										aria-label={t('kinto.booking.register')}
										variant='contained'
										color='primary'
										fullWidth
										onClick={() => {
											doSetReturnToBookingAfterRegistration(true)
											navigate('/bli-medlem')
										}}
									>
										{t('kinto.booking.register')}
									</Button>
									<Button
										aria-label={t('kinto.booking.log-in')}
										variant='outlined'
										color='primary'
										fullWidth
										onClick={triggerLogin}
									>
										{t('kinto.booking.log-in')}
									</Button>
								</>
							)}
						</div>
					</>
				) : (
					<>
						{bookingLoading ? (
							<CircularProgress />
						) : (
							<InformationBox
								color='blue'
								text={t('kinto.booking.not-available')}
							/>
						)}
						{availableCars.length > 0 && (
							<div {...bem('other-cars')}>
								<div {...bem('other-cars', 'car')}>
									<div {...bem('other-cars', 'text')}>
										<h4 className='h5'>
											{t('kinto.booking.other-cars-at')}{' '}
											{selectedStation.name}
										</h4>
									</div>
									{availableCars.map((car) => (
										<CarPaper
											key={car.id}
											car={car}
											withButton
											onSelect={(car) => {
												doSetBookingSelectedCar(car)
												!isCustomerLoggedIn || isMissingInformation === true
													? doFetchAvailabelVehicles()
													: setNextStep(BOOKING_STEPS.CHECKOUT)
											}}
										/>
									))}
								</div>
							</div>
						)}
						{loading ? (
							<CircularProgress />
						) : (
							<div {...bem('other-cars')}>
								{closestStations.length > 0 && (
									<h3 className='h4'>{t('kinto.booking.other-cars')}</h3>
								)}
								{closestStations.map(({ station, availableVehicles }) => (
									<div {...bem('other-cars', 'car')} key={station.id}>
										<div {...bem('other-cars', 'text')}>
											<h4 className='h5'>
												{t('kinto.booking.cars-at', [station.name])}
											</h4>
											{position && position.latitude && (
												<p>
													{readableDistance(
														distanceInMeterBetweenEarthCoordinates(
															station.location.lat,
															station.location.lng,
															position.latitude,
															position.longitude,
														),
													)}
												</p>
											)}
										</div>

										{availableVehicles.map((car) => (
											<CarPaper
												key={car.id}
												car={car}
												withButton
												onSelect={(car) => {
													doSetBookingSelectedCar(car)
													doSelectStation(station)
													!isCustomerLoggedIn || isMissingInformation
														? doFetchAvailabelVehicles()
														: setNextStep(BOOKING_STEPS.CHECKOUT)
												}}
											/>
										))}
									</div>
								))}
							</div>
						)}
					</>
				))}
		</div>
	)
}

export default SelectBookingDates

function CarPaper({ ...props }) {
	return (
		<Paper>
			<CarCard {...props} />
		</Paper>
	)
}
