import './TimePicker.scss'

import React, { useEffect, useRef } from 'react'
import BEMHelper from 'react-bem-helper'
import format from 'date-fns/format'

const bem = new BEMHelper('time-picker')

const TimePicker = ({ endTime, handleClick, availableTimes = [] }) => {
	let ref = useRef()

	useEffect(() => {
		if (ref.current) {
			ref.current.scrollIntoView({
				block: 'start',
			})
		}
	}, [])

	return (
		<div {...bem('')}>
			<div
				{...(endTime
					? { ...bem('container', 'left') }
					: { ...bem('container') })}
			>
				<div {...bem('time-list')}>
					<ul>
						{availableTimes.map((time) => {
							const formattedTime = format(new Date(time), 'HH:mm')
							return formattedTime === '07:00' ? (
								<button onClick={() => handleClick(time)} key={time} ref={ref}>
									<span {...bem('time-item')} value={formattedTime}>
										{formattedTime}
									</span>
								</button>
							) : (
								<button onClick={() => handleClick(time)} key={time}>
									<span {...bem('time-item')} value={formattedTime}>
										{formattedTime}
									</span>
								</button>
							)
						})}
					</ul>
				</div>
			</div>
		</div>
	)
}

export default TimePicker
